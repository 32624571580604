<template>
  <div class="deliverchangepwd">
    <p class="lead">Change password to ensure security of your account.</p>
    <div class="row">
      <div class="col-sm-12" id="DpProfPwdbl">
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group input-password">
              <label for="input-middlename" class="control-label"
                >Current Password</label
              >
              <input
                oncopy="return false"
                onpaste="return false"
                type="password"
                required
                class="form-control"
                placeholder="Current Password"
                v-model="changePassword.current_password"
                @input="valCurrentPassword()"
                ref="inputProfPwdDp"
              />
              <span class="toggleIcon" @click="togglePwd()" ref="toggleIconDp"
                ><i class="fa fa-eye"></i
              ></span>
              <!-- <div class="error-message" v-show="!currentPasswordValid && changePassword.current_password!=''">At least 8 characters, include one lowercase and one uppercase letter</div> -->
              <div
                v-show="
                  !currentPasswordValid && changePassword.current_password != ''
                "
              >
                <span style="color: grey;font-size:10px;">
                  <span
                    v-for="(vrule, index) in valRuleArray"
                    :key="vrule.index"
                  >
                    {{ vrule.valRule }} <span v-html="vrule.valIcon"></span
                    >{{ valRuleArray.length - 1 != index ? ", " : "" }}
                  </span>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group input-password">
              <label for="input-middlename" class="control-label"
                >New Password</label
              >
              <input
                oncopy="return false"
                onpaste="return false"
                type="password"
                id="password"
                name="password"
                class="form-control"
                placeholder="New Password"
                v-model="changePassword.password"
                @input="valNewPassword()"
                ref="inputProfNewPwdDp"
              />
              <span
                class="toggleIcon"
                @click="toggleNewPwd()"
                ref="toggleNewIconDp"
                ><i class="fa fa-eye"></i
              ></span>
              <!-- <div class="error-message" v-show="!newPasswordValid && changePassword.password!=''">At least 8 characters, include one lowercase and one uppercase letter</div> -->
              <div v-show="!newPasswordValid && changePassword.password != ''">
                <span style="color: grey;font-size:10px;">
                  <span
                    v-for="(vrule, index) in valRuleArrayNew"
                    :key="vrule.index"
                  >
                    {{ vrule.valRule }} <span v-html="vrule.valIcon"></span
                    >{{ valRuleArrayConf.length - 1 != index ? ", " : "" }}
                  </span>
                </span>
                <div
                  v-show="currentNewPwdMatch"
                  style="color: red;font-size: 11px;"
                >
                  Your new password match with the old password
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <div class="form-group">
              <label for="input-middlename" class="control-label"
                >Retype Password</label
              >
              <input
                oncopy="return false"
                onpaste="return false"
                type="password"
                id="password_two"
                name="password_two"
                class="form-control"
                v-model="password_two"
                placeholder="New Password"
                :disabled="changePassword.password == ''"
                ref="inputProfNewPwdDp1"
                @input="valConfPassword()"
              />
              <span
                class="toggleIcon1"
                @click="toggleNewPwd1()"
                ref="toggleNewIconDp"
                ><i class="fa fa-eye"></i
              ></span>
              <!-- <div class="error-message" v-show="!confPasswordValid && password_two!=''">At least 8 characters, include one lowercase and one uppercase letter</div> -->
              <div v-show="!confPasswordValid && password_two != ''">
                <span style="color: grey;font-size:10px;">
                  <span
                    v-for="(vrule, index) in valRuleArrayConf"
                    :key="vrule.index"
                  >
                    {{ vrule.valRule }} <span v-html="vrule.valIcon"></span
                    >{{ valRuleArrayConf.length - 1 != index ? ", " : "" }}
                  </span>
                </span>
              </div>
              <!-- <div class="error-message" v-show="passwordMissMatched && password_two!=''">Password missmatched</div> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 footerClass1">
            <div class="form-group text-right">
              <button
                type="button"
                class="btn delprof-sbtn"
                @click="resetPassword()"
                :disabled="!formIsValid"
              >
                Reset Password
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinProfile from "../../../mixins/deliver/APIProfile";
export default {
  mixins: [mixinProfile],
  data: function() {
    return {
      password_two: "",
      changePassword: {
        current_password: "",
        password: "",
      },
      isDisable: false,
      RetypePw: false,
      currentPasswordValid: false,
      newPasswordValid: false,
      confPasswordValid: false,
      passwordMissMatched: false,
      valRuleArray: [
        {
          valRule: "At least 8 characters",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One lowercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One uppercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One special character",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
      ],
      valRuleArrayNew: [
        {
          valRule: "At least 8 characters",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One lowercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One uppercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One special character",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: 'One numerical character',
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
        }
      ],
      valRuleArrayConf: [
        {
          valRule: "At least 8 characters",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One lowercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One uppercase letter",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "One special character",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: "Match password",
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>',
        },
        {
          valRule: 'One numerical character',
          valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
        }
      ],
      currentNewPwdMatch: false,
    };
  },
  computed: {
    formIsValid() {
      return (
        this.confPasswordValid &&
        this.currentPasswordValid &&
        this.newPasswordValid
      );
    },
  },
  watch: {
    changePassword: {
      handler(val) {
        if (val.password == "") {
          this.password_two = "";
        }
      },
      deep: true,
    },
    password_two: function(val) {
      if (val != this.changePassword.password) {
        this.passwordMissMatched = true;
      } else {
        this.passwordMissMatched = false;
      }
    },
  },
  methods: {
    checkPasswordMatch: function(key) {
      if (this.changePassword.password !== key) {
        this.RetypePw = true;
      } else {
        this.RetypePw = false;
      }
    },
    resetPassword: async function() {
      try {
        await this.putProfileBacicInfo(this.changePassword);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "success change password",
          showConfirmButton: false,
          timer: 1500,
        });

        this.$store.dispatch("logoutdeliver");
        this.$router.push("/deliver-login") ;
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    valCurrentPassword: function() {
      var pattOne = /(?=.*[a-z])/;
      var pattTwo = /(?=.*[A-Z])/;
      var pattThree = /(?=.*\W)/;
      // var pattFour = /((?=.*[a-z])(?=.*[A-Z]).{8})/;

      if (pattOne.test(this.changePassword.current_password) == true) {
        this.valRuleArray[1].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArray[1].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattTwo.test(this.changePassword.current_password) == true) {
        this.valRuleArray[2].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArray[2].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattThree.test(this.changePassword.current_password) == true) {
        this.valRuleArray[3].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArray[3].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (this.changePassword.current_password.length >= 8 == true) {
        this.valRuleArray[0].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArray[0].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (
        this.changePassword.current_password == this.changePassword.password
      ) {
        this.currentNewPwdMatch = true;
      } else {
        this.currentNewPwdMatch = false;
      }

      if (
        pattOne.test(this.changePassword.current_password) == true &&
        pattTwo.test(this.changePassword.current_password) == true &&
        pattThree.test(this.changePassword.current_password) == true &&
        this.changePassword.current_password.length >= 8
      ) {
        this.currentPasswordValid = true;
      } else {
        this.currentPasswordValid = false;
      }
    },
    valNewPassword: function() {
      var pattOne = /(?=.*[a-z])/;
      var pattTwo = /(?=.*[A-Z])/;
      var pattThree = /(?=.*\W)/;
      var pattFour = /[0-9]/;
      // var pattFour = /((?=.*[a-z])(?=.*[A-Z]).{8})/;

      if (pattOne.test(this.changePassword.password) == true) {
        this.valRuleArrayNew[1].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayNew[1].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattTwo.test(this.changePassword.password) == true) {
        this.valRuleArrayNew[2].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayNew[2].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattThree.test(this.changePassword.password) == true) {
        this.valRuleArrayNew[3].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayNew[3].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattFour.test(this.changePassword.password) == true) {
        this.valRuleArrayNew[4].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayNew[4].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (this.changePassword.password.length >= 8 == true) {
        this.valRuleArrayNew[0].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayNew[0].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (
        this.changePassword.current_password == this.changePassword.password
      ) {
        this.currentNewPwdMatch = true;
      } else {
        this.currentNewPwdMatch = false;
      }

      if (
        pattOne.test(this.changePassword.password) == true &&
        pattTwo.test(this.changePassword.password) == true &&
        pattThree.test(this.changePassword.password) == true &&
        pattFour.test(this.changePassword.password) == true &&
        this.changePassword.password.length >= 8 &&
        this.currentNewPwdMatch == false
      ) {
        this.newPasswordValid = true;
      } else {
        this.newPasswordValid = false;
      }
    },
    valConfPassword: function() {
      var pattOne = /(?=.*[a-z])/;
      var pattTwo = /(?=.*[A-Z])/;
      var pattThree = /(?=.*\W)/;
      var pattFour = /[0-9]/;
      // var pattFour = /((?=.*[a-z])(?=.*[A-Z]).{8})/;

      if (pattOne.test(this.password_two) == true) {
        this.valRuleArrayConf[1].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayConf[1].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattTwo.test(this.password_two) == true) {
        this.valRuleArrayConf[2].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayConf[2].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattThree.test(this.password_two) == true) {
        this.valRuleArrayConf[3].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayConf[3].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (pattFour.test(this.password_two) == true) {
        this.valRuleArrayConf[5].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayConf[5].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (this.password_two.length >= 8 == true) {
        this.valRuleArrayConf[0].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayConf[0].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (this.changePassword.password === this.password_two) {
        this.valRuleArrayConf[4].valIcon =
          '<i style="color: green" class="fa fa-check-circle"></i>';
      } else {
        this.valRuleArrayConf[4].valIcon =
          '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if (
        pattOne.test(this.password_two) == true &&
        pattTwo.test(this.password_two) == true &&
        pattThree.test(this.password_two) == true &&
        pattFour.test(this.password_two) == true &&
        this.password_two.length >= 8 &&
        this.changePassword.password == this.password_two
      ) {
        this.confPasswordValid = true;
      } else {
        this.confPasswordValid = false;
      }
    },
    togglePwd: function() {
      if (this.$refs.inputProfPwdDp.type === "password") {
        this.$refs.inputProfPwdDp.type = "text";
        this.$refs.toggleIconDp.innerHTML = `<i class="fa fa-eye-slash"></i>`;
      } else {
        this.$refs.inputProfPwdDp.type = "password";
        this.$refs.toggleIconDp.innerHTML = `<i class="fa fa-eye"></i>`;
      }
    },
    toggleNewPwd: function() {
      if (this.$refs.inputProfNewPwdDp.type === "password") {
        this.$refs.inputProfNewPwdDp.type = "text";
        this.$refs.toggleNewIconDp.innerHTML = `<i class="fa fa-eye-slash"></i>`;
      } else {
        this.$refs.inputProfNewPwdDp.type = "password";
        this.$refs.toggleNewIconDp.innerHTML = `<i class="fa fa-eye"></i>`;
      }
    },
    toggleNewPwd1: function() {
      if (this.$refs.inputProfNewPwdDp1.type === "password") {
        this.$refs.inputProfNewPwdDp1.type = "text";
        this.$refs.toggleNewIconDp1.innerHTML = `<i class="fa fa-eye-slash"></i>`;
      } else {
        this.$refs.inputProfNewPwdDp1.type = "password";
        this.$refs.toggleNewIconDp1.innerHTML = `<i class="fa fa-eye"></i>`;
      }
    },
  },
};
</script>
<style>
.error-message {
  margin-top: 10px;
  color: red;
}
#DpProfPwdbl .input-password input[type="password"] {
  position: relative;
}
#DpProfPwdbl .input-password span.toggleIcon {
  position: relative;
  display: block;
  float: right;
  top: -31px;
  right: 7px;
  cursor: pointer;
}
#DpProfPwdbl .toggleIcon1 {
  position: relative;
  display: block;
  float: right;
  top: -31px;
  right: 7px;
  cursor: pointer;
}
#DpProfPwdbl .input-password .fa {
  font-size: 15px;
}
.deliverchangepwd .delprof-sbtn {
  background: #5779ae !important;
  color: #ffffff !important;
  border: none;
}
.deliverchangepwd .delprof-sbtn:hover {
  color: #000000 !important;
}
</style>
