<template>
    <div class="deliverprofaddr">
        <p class="lead">You can change your address here.</p>
        <div class="row">
            <div class="col-sm-12">
                
            <div  v-for="(item, index) in addressArray" :key="index">

                <br>
                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group required">
                        <label>Country</label>
                            <select class="form-control"  @change="changeCountries($event.target.value, index, true)" v-model="item.country" >
                                <option value=""> --- Please Select --- </option>
                                <option v-for="(item, val) in countries" :key="val" :value="item._id">{{ item.country_name}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group required">
                        <label>Province</label>
                            <select class="form-control"  @change="changeProvince($event.target.value, index, true)" v-model="item.province" >
                                <option value=""> --- Please Select --- </option>
                                <option  v-for="(item, val) in provinceArray[index]" :key="val" :value="item._id">{{ item.province_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-6">
                        <div class="form-group required">
                        <label>District / Area</label>
                           <select class="form-control" @change="changeRegion($event.target.value, index, true)" v-model="item.region">
                                <option value=""> --- Please Select --- </option>
                                <option  v-for="(item, val) in regionArray[index]" :key="val" :value="item._id">{{ item.region_name}}</option>
                          </select>
                        </div>
                    </div>
                    <div class="col-sm-6">
                        <div class="form-group required">
                        <label>City</label>
                            <select class="form-control" v-model="item.city" @change="changeCity($event.target.value, index, true)">
                                <option value=""> --- Please Select --- </option>
                                <option  v-for="(item, val) in cityArray[index]" :key="val" :value="item._id">{{ item.city_name}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                  <div class="col-sm-6">
                    <label class="control-label" for="input-password">Address Line 1</label>
                    <input type="text"  placeholder="Home Number" class="form-control" v-model="item.address_line_1"/>
                  </div>
                  <div class="col-sm-6">
                    <label class="control-label" for="input-password">Address Line 2</label>
                    <input type="text" placeholder="Lane" class="form-control" v-model="item.address_line_2"/>
                  </div>
                </div>

                <!-- <div class="row">
                    <div class="col-sm-6">
                        <label class="control-label " for="input-password">Post Code</label>
                        <input type="text" class="form-control" v-model="item.postal_code" />
                    </div>
                     <div class="col-sm-6" style="margin-top:30px;">
                      <button class="btn btn-danger btn-sm" v-if="index > 0" @click="DeleteClick(index, item._id)" ><span class="fa fa-close"></span> &nbsp;&nbsp;Delete Address </button>
                    </div> 
                </div>    -->
                
                <br/>
                <!-- <div class="row" >
                    <div class="col-sm-3">                            
                        <label class="radio-inline">
                        <input type="checkbox" :value="isCheck" name="newsletter" checked="checked" v-model="item.is_biling_address" @change="clickButton(item.is_biling_address, index)"> Billing to this address
                        </label>
                    </div>
                    <div class="col-sm-3 text-right" >
                    </div>
                    <div class="col-sm-6">
                    </div>     
                </div> -->
                <br/>
            </div>
            <div class="row">
                <div class="col-sm-12">
                    <div class="form-group text-right">
                    <button class="btn delprof-sbtn" :disabled="!formIsValid" @click="submitProfileAddress" >Submit</button>
                    </div>
                </div>
            </div>
            
        </div>
        </div>
    </div>
</template>
<script>
import mixinCommon from "@/mixins/APICommon";
import mixinProfile from "@/mixins/deliver/APIProfile";
export default {
  mixins: [mixinCommon, mixinProfile],
  data: function () {
    return {
      breadcrumb: [
      {
        text: "Profile",
        active: true
      }
      ],
      valIndex: 0,
      countries: [],
      province: [],
      region: [],
      city: [],
      provinceArray:[],
      regionArray:[],
      cityArray:[],
      addressArray: [{
        address_line_1: "",
        address_line_2: "",
        country: "",
        province: "",
        region: "",
        city: "",
        is_biling_address: true,
        primary: false
      }],
      address:{
        address_line_1: "",
        address_line_2: "",
        country: "",
        province: "",
        region: "",
        city: "",
        is_biling_address: true,
        primary: false
      },
      isCheck: true,
      multiAddress: true
    }
  },
  computed: {
    formIsValid() {
      return (
        this.addressArray[0].address_line_1 &&
        this.addressArray[0].address_line_2 &&
        this.addressArray[0].city &&
        this.addressArray[0].country &&
        this.addressArray[0].province &&
        this.addressArray[0].region
      );
    },
  },
  created(){
    this.handleBreadcrumb();
    this.getCountryDetails();
    this.getPersanalInfo();
  },
  methods: {
    changeCity: async function(event, index, useraction = false) {
      let pCode = this.city.filter(obj => {
        return obj._id === event;
      });
      // this.addressArray[index].postal_code = pCode[0].postal_code;
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    addNewClick: function() {
      if (this.addressArray.length <= 1) {
          if (this.valIndex === 0) {
          this.addressArray[0].primary = true;
        }
        this.valIndex = this.valIndex + 1;
        this.addressArray.push( this.address );
      }
    },
    getCountryDetails: async function() {
      try {
          let response = await this.getCountry(); 
          this.countries = response;
          if (this.countries.length === 1) {
            for (let index = 0; index < this.addressArray.length; index++) {
              const element = this.addressArray[index];
              element.country = this.countries[0]._id;
            }
          }
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        })
      }
    },
    changeCountries: async function(event, index, useraction = false) {
      try {
          let response = await this.getProvince(event); 
          this.province = response;
          this.$set(this.provinceArray, index, response);
          this.regionArray[index] = [];
          this.cityArray[index] = [];
          // this.provinceArray[index] = response;
          // // RESETING 
           if(Object.keys(response).length === 0 || useraction === true){
            this.addressArray[index].province = "";
            this.addressArray[index].region = "";
            this.addressArray[index].city = "";
           } 
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        })
      }
    },
    changeProvince: async function(event, index, useraction = false) {
      try {
          let response = await this.getRegion(event); 
          this.region = response;
          this.$set(this.regionArray, index, response);
           // RESETING 
          this.cityArray[index] = [];
          if(Object.keys(response).length === 0 || useraction === true){
            this.addressArray[index].region = "";
            this.addressArray[index].city = "";
          }
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        })
      }
    },
    changeRegion: async function(event, index, useraction = false) {
      try {
          let response = await this.getCity(event); 
          this.city = response;
          this.$set(this.cityArray, index, response);
          // RESETING  
          if(Object.keys(response).length === 0 || useraction === true){
            this.addressArray[index].city = ""; 
          }  
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        })
      }
    },
    submitProfileAddress: async function() {
        let obj = {};
        obj.addresses = this.addressArray;
        try {
          let response = await this.putProfileAddress(obj);
          if(!this.addressArray[0]._id){
            await this.$parent.postRequiredStage(2);
            this.$store.commit("updatedeliverystage", 2); 
          }
          let self = this;
          this.$swal.fire({
            position: 'center',
            type: 'success',
            title: 'Submit success!',
            showConfirmButton: false,
            timer: 1500
            }).then(function() {                  
            // self.$parent.activateTab(3);
          })
          this.$parent.checkRequiredStage();
        } catch (error) {address
          this.$swal.fire({
            position: 'center',
            type: 'error',
            title: 'Submit error!',
            showConfirmButton: false,
            timer: 1500
          })
        }
    },
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        if (response.addresses.length === 0) {
            this.addressArray = [{
                address_line_1: "",
                address_line_2: "",
                country: this.countries[0]._id,
                province: "",
                region: "",
                city: "",
                is_biling_address: true,
                primary: false
            }];
          await this.changeCountries(this.countries[0]._id, 0);
        } else {
          for (let index = 0; index < response.addresses.length; index++) {
            await this.changeCountries(this.countries[0]._id, index);
            await this.changeProvince(response.addresses[index].province, index);
            await this.changeRegion(response.addresses[index].region, index);
            this.addressArray = response.addresses; 
          }            
            this.addressArray = response.addresses;
        }
      } catch (error) {
        console.log(error);
      }
    },
    DeleteClick: async function(index, itemid){
      this.addressArray.splice(index, 1);
      this.provinceArray.splice(index, 1);
      this.addressArray[0].is_biling_address = true;   
      if(itemid){
        await this.deleteProfileAddress(itemid);
      }   
    },
    clickButton: function(val, tickindex) {
      if(this.addressArray[tickindex].is_biling_address === false){
        if(this.addressArray.length < 2){
          this.addNewClick();
        }
      }      
      for (let index = 0; index < this.addressArray.length; index++) {
        if(val === false){
          if(tickindex != index){
            this.addressArray[index].is_biling_address = true;
          }
        } else {
          if(tickindex != index){
            this.addressArray[index].is_biling_address = false;
          }
        }         
      }
        if (val === true) {
            this.isCheck = false
            this.multiAddress = false
        } else {
            this.isCheck = true
            this.multiAddress = true
        }
    }
  }
}
</script>
<style>
.deliverprofaddr .delprof-sbtn{
    background: #5779ae !important;
    color: #ffffff;
    border: none;
}
.deliverprofaddr .delprof-sbtn:hover{
    color: #000000;
}
</style>
