<template>
    <div style="">
        <h2 class="title rel-city">Package Dispatch - {{deliveryCity}}</h2>
        <ul class="nav nav-tabs">
            <li v-on:click="activetab=1" :class=" activetab === 1 ? 'active' : '' "><a data-toggle="tab" href="#personal">Pending <span class="pending-count">{{pendingCount}}</span></a></li>
            <li v-on:click="activetab=2" :class=" activetab === 2 ? 'active' : '' "><a data-toggle="tab" href="#address">Delivered <span class="delivered-count">{{deliveryCount}}</span></a></li>
        </ul>
        <div class="tab-content content-inner content-inner-profile">
            <div v-if="activetab === 1" id="personal" class="tab">
                <DispatchPending :relcityid="cityId"></DispatchPending>
            </div>
            <div v-if="activetab === 2" id="address" class="tab">
                <DispatchDelivered :relcityid="cityId"></DispatchDelivered>
            </div>
        </div>         
    </div>
</template>
<script>
// @ is an alias to /src
// import ProfilePersonal from '@/components/Deliver/profile/ProfilePersonal'
// import ProfileAddress from '@/components/Deliver/profile/ProfileAddress'
// import ProfileBankAccount from '@/components/Deliver/profile/ProfileBankAccount'
// import ProfilePassword from '@/components/Deliver/profile/ProfilePassword'
// import ProfileSettings from '@/components/Deliver/profile/ProfileSettings'
// import ProfileKyc from '@/components/Deliver/profile/ProfileKyc'
import mixinOrder from "@/mixins/deliver/APIOrder";
import DispatchPending from '@/components/Deliver/Dispatch/DispatchPending'
import DispatchDelivered from '@/components/Deliver/Dispatch/DispatchDelivered'
import APIProfile from "../../../mixins/deliver/APIProfile";
export default {
    name: 'Myaccount',
    mixins: [APIProfile, mixinOrder],
    data(){
        return{
            selectedContactType: '',
            postObject: {},
            blockLoading: 0,
            activetab: 1,
            pickup: true,
            cityId: "",
            deliveryCity: "",
            pendingCount: "",
            deliveryCount: ""
        }
    },
    components: {
        // ProfilePersonal,
        // ProfileAddress,
        // ProfilePassword,
        // ProfileSettings,
        // ProfileBankAccount,
        // ProfileKyc,
        DispatchPending,
        DispatchDelivered
    },
    mounted:function(){
        // this.getParamData();
    },
    created: function () {
        // `this` points to the vm instance
        this.getParamData();
        
    },
    methods:{
        activateTab(tabnum){ 

            tabnum = tabnum + 1;
           

            if(tabnum === 2){
                this.$swal({
                        title: 'Please Update Address Information',
                        text: "",
                        type: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                        this.activetab = tabnum;
                    });
            }

            if(tabnum === 3){ 
                this.$swal({
                    title: 'Please Update Delivery Rates',
                    text: "",
                    type: 'warning',
                    showCancelButton: false,
                    confirmButtonColor: '#3085d6',
                    confirmButtonText: 'OK'
                }).then((result) => {
                    this.$router.push("/deliver-rate-configure/0");    
                });
            }

            if(tabnum === 4){
                if(this.pickup){
                    this.$swal({
                        title: 'Please Update Pickup Address',
                        text: "",
                        type: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        confirmButtonText: 'OK'
                    }).then((result) => {
                       this.$router.push("/deliver-pickup-address-submit/0");   
                    });

                                        
                } 
                
            }
            
        
        },
        checkRequiredStage: async function() {
            let response = await this.getDashboardStage();

            if(response !== null){
                this.currentstage = response.complated_status;
                this.activateTab(this.currentstage);
                return response.complated_status
            } else {
                this.currentstage = 1;
                this.activateTab(this.currentstage);
                return null
            } 
        },
        postRequiredStage: async function(stage) {
            let obj = {
                    "complated_status": stage
            }

            let response = await this.postDashboardStage(obj);
        
        },
        getRateDetails: async function() {
            try {
                this.blockLoading = 0;
                this.postObject = await this.getProfileBacicInfo(); 

                if(this.postObject.hasOwnProperty('pickup')){
                    this.pickup = this.postObject.pickup;
                } else {
                    this.pickup = false;
                }
                

                this.blockLoading = 1;
            } catch (error) {
                this.$swal.fire({
                    position: 'center',
                    type: 'error',
                    title: "Could not submit",
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },
        getParamData: async function() {
             this.cityId = this.$route.params.id?this.$route.params.id:localStorage.order_dispatch_id;
            try {
              let pendingOrders = await this.getCityDeliveryDispatchList('readyToPick',this.cityId);
              let deliveredOrders = await this.getCityDeliveryDispatchList('deliver',this.cityId);
              
              this.pendingCount =  this.$route.params.ready_to_pick?this.$route.params.ready_to_pick: pendingOrders.length;  
              this.deliveryCount = this.$route.params.delivered?this.$route.params.delivered:deliveredOrders.length;
              this.deliveryCity = this.$route.params.city_name?this.$route.params.city_name:pendingOrders[0].order_id.address_id.city.city_name;
            } catch (error) {
                throw error;
            }
        }
        //   createContact(){
        //       $('#mdl-create-contact').modal('show');
        //   }
    }
}
</script>
<style scoped>
.content-inner-profile{
    margin-bottom: 0px;
    padding-bottom: 0px;
    min-height: 607px !important;
}
.rel-city{
    margin-bottom: 25px;
}
.pending-count{
    display: inline-block;
    background: #ffd338;
    color: #ffffff;
    width: 29px;
    height: 29px;
    border-radius: 15px;
    text-align: center;
    line-height: 29px;
    margin-left: 12px;
}
.delivered-count{
    display: inline-block;
    background: #34c57c;
    color: #ffffff;
    width: 29px;
    height: 29px;
    border-radius: 15px;
    text-align: center;
    line-height: 29px;
    margin-left: 12px;
}
</style>
