<template>
<div class="classWell" style=""> 
  <div class="panel-body row">
    <div class="col-sm-12" style="padding-left:0px;">
      <div class="input-group col-sm-3" style="padding-left:15px;">
        <input type="text" v-model="searchKey" class="form-control" id="input-store" placeholder="Search City" value="" name="voucher">          
      </div>
    </div>
  </div>  
  <h2 class="title">Delivery Dispatch</h2>
  <div class="row tbl-container">
    <table id="example" class="table table-striped table-bordered" >
      <thead>
        <tr>
          <th scope="col">City</th>
          <th scope="col">Pending</th>
          <th scope="col">Delivered</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="column in tableItems" :key="column._id._id">
          <td class="bold-text-cont">{{ column._id.city_name }}</td>
          <td class="text-center bold-text-cont">{{ column.ready_to_pick }}</td>
          <td class="text-center bold-text-cont">{{ column.delivered }}</td>
          <td class="product-action-style text-center">
            <button type="button" @click="viewCityOrders(column._id._id,column._id.city_name,column.ready_to_pick,column.delivered)" class="btn btn-sm delprof-sbtn">View</button>&nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row text-right">
    <div class="col-sm-12">
        <div class="mt-3">
      <b-pagination 
      v-model="currentPage" 
      :per-page="pageSize"
      :total-rows="rowcount" 
      align="right"></b-pagination>
    </div>  

    </div>
    
  </div>
  

</div> 
</template>
<script> 
import mixinCommon from "@/mixins/APICommon";
import mixinRates from "@/mixins/deliver/APIRates";
import mixinOrder from "@/mixins/deliver/APIOrder";
import { async } from 'q';
import { functions } from 'firebase';
export default {
  components: { 
  },
  mixins: [mixinCommon, mixinRates, mixinOrder],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Delivery Rates",
          href: "#",
          active: true
        }
      ],
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      storeListData: [],
      deliveryDispatchList: [],
      provincelist: [],
      packsizeArray: [
        {id: 1, text: 'Small'},
        {id: 2, text: 'Medium'},
        {id: 3, text: 'Large'}
      ]
    }
  },
  watch: {
    searchKey: function(val) {
      this.searchCustomers(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchCustomers(this.searchKey);
    }
  },
  created(){
    this.handleBreadcrumb(); 
    // this.handleProvince();
    this.handleGetDispatchList();
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    // handleProvince: async function() {
    //   try {
    //       let response = await this.getProvince("5d8b9e5dfb195816aaea61b3"); 
    //       this.provincelist = response; 
              
    //   } catch (error) {
    //     this.$swal.fire({
    //               position: 'center',
    //               type: 'error',
    //               title: error.data.message,
    //               showConfirmButton: false,
    //               timer: 1500
    //             })      
    //   }
    // },
    filterProviceName: function(id){
      var provin =  this.provincelist.filter(function(province) {         
        return province._id === id;
      });

      if(provin.length > 0){
        return provin[0].province_name;
      } else {
        return "";
      }
      
    },
    filterPackageSize: function(id){
      var size =  this.packsizeArray.filter(function(packsize) {         
        return packsize.id === id;
      });

      if(size.length > 0){
        return size[0].text;
      } else {
        return "";
      }
      
    },
    statusToText: function(status){      
      if(status === 1){
        return "Active";
      } else {
        return "Deactive";
      }      
    },
    handleGetDispatchList: async function() {
      try {
          let response = await this.getDeliveryDispatchList(); 
          this.deliveryDispatchList = response;
          this.searchCustomers();
             
      } catch (error) {   
            this.$swal.fire({
            position: 'center',
            type: 'error',
            title: 'Error',
            showConfirmButton: false,
            timer: 1500
            }); 
      }
    },
    searchCustomers: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.tableItems = this.deliveryDispatchList.slice(0, pagesize);
        this.rowcount = this.deliveryDispatchList.length;
      } else {
        this.tableItems = this.deliveryDispatchList
          .filter(obj => {
            {
              return (
                (obj._id.city_name &&
                  obj._id.city_name.toLowerCase().includes(keyword.toLowerCase())) 
              );
            }
          })
          .slice(0, pagesize);

          this.rowcount = this.deliveryDispatchList.length;
      }
      
    },   
    pagination: function(pagesize, currentPage) {
      
      let pagesizeint = parseInt(this.pageSize);

      let endsize = pagesize * currentPage;
      let startsize = 0;

      if(currentPage === 1){
        startsize = 0;
      } else {
        startsize = endsize - (pagesize) ;
      }     

      this.tableItems = this.deliveryDispatchList.slice(startsize, endsize);

    }, 
    viewCityOrders(cityId,cityName,pendingCount,deliveredCount){
        if ('order_dispatch_id' in localStorage) {
            localStorage.removeItem("order_dispatch_id");
        }
        localStorage.setItem("order_dispatch_id", cityId);
        this.$router.push({
           name: "Deliver Dispatch City", 
           params: { 
               id: cityId,
               city_name: cityName,
               ready_to_pick: pendingCount,
               delivered: deliveredCount
            } 
        });
    },
    pushEdit(pid) {
      this.$router.push({ name: "Delivery Rate Configure", params: { id: pid } });
    },
    handleCreate: function() {
      this.$router.push({ name: "Delivery Rate Configure", params: { id: 0 } });
    },
    pushDelete: async function(id) {
      let self = this;
      this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            self.DeleteRate(id);
          }
        })
    },
    DeleteRate: async function(id) {
      try {
          let response = await this.deleteDeliveryRate(id);
          if(response.success){
            if(response.success === 1){
              this.$swal.fire({
                    position: 'center',
                    type: 'success',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
            } else {
              this.$swal.fire({
                    position: 'center',
                    type: 'error',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
          }
          this.handleGetRatesList();
          this.searchCustomers(""); 
              
      } catch (error) {   
        throw error; 
      }
    }

  }, 
}

</script>
<style>
#container {
	height: 300px;
	min-width: 310px;
	max-width: 800px;
    margin: 0 auto;
}
.tbl-container{
  padding-left: 15px;
  padding-right: 15px;
}
#button-bar {
    min-width: 310px;
    max-width: 800px;
    margin: 0 auto;
}
.delprof-sbtn{
    background: #5779ae !important;
    color: #ffffff !important;
    border: none;
}
.delprof-sbtn:hover{
    color: #000000 !important;
}
.bold-text-cont{
    font-size: 14px;
    font-weight: 600;
    color: #3a3a3a;
}
@media screen and (max-width: 1290px){
  .tbl-container{
    width: 100%;
    overflow-x: scroll;
  }
}
</style>