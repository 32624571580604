<template>
<div class="classWell" style=""> 
  <div class="row tbl-container">
    <table id="example" class="table table-striped table-bordered" >
      <thead>
        <tr>
          <th scope="col">Order Id</th>
          <th scope="col">Status</th>
          <th scope="col">Quantity</th>
          <th scope="col">Amount</th>
          <th scope="col">Payment Method</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="column in tableItems" :key="column._id">
          <td class="text-center">
              <div class="col-lg-6 col-md-6 col-sm-6 user-order-left">
                  <div class="bold-text-cont">{{ column.order_env_number}}</div>
                  <div class="user-det-name">{{ column.order_id.user_id.first_name}} {{ column.order_id.user_id.last_name}}</div>
              </div>
              <div class="col-lg-6 col-md-6 col-sm-6 user-order-right">
                  <div class="date-block">Date: {{column.date}}</div>
                  <div class="city-block">{{column.order_id.address_id.city.city_name}}</div>
              </div>
         </td>
         <td class="text-center"><span style="background: #ffd300;" class="delstatus-badge">Pending</span></td>
          <!-- <td class="text-center">{{ column.zone_two_text }}</td> -->
          <td class="text-center bold-text-cont">{{ column.qty }} Item</td>
          <td class="text-center bold-text-cont">Rs {{ column.total_charge }}</td>
          <td class="text-center bold-text-cont">
                {{ column.payment_type== 1? "Credit": "" }}
                {{ column.payment_type== 2? "Cash on Delivery": "" }}
                {{ column.payment_type== 3? "Bank Transfer": "" }}
                {{ column.payment_type== 4? "Card": "" }}
              </td>
          <td class="product-action-style text-center">
            <button type="button" @click="viewOrder(column._id)" class="btn btn-sm delprof-sbtn">View</button>&nbsp;
            <button type="button" @click="printOrder(column._id)" class="btn btn-sm delprof-sbtn">Print</button>&nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row text-right">
    <div class="col-sm-12">
        <div class="mt-3">
      <b-pagination 
      v-model="currentPage" 
      :per-page="pageSize"
      :total-rows="rowcount" 
      align="right"></b-pagination>
    </div>  
    </div>
  </div>
   <div class="go-back-btn">
        <button class="btn btn-sm delprof-sbtn" @click="viewDispatchAll()">Back</button>
   </div>
</div> 
</template>
<script> 
import Domain from "@/mixins/domain.js";
import mixinCommon from "@/mixins/APICommon";
import mixinRates from "@/mixins/deliver/APIRates";
import mixinOrder from "@/mixins/deliver/APIOrder";
import { async } from 'q';
import { functions } from 'firebase';
export default {
  components: { 
  },
  props: ['relcityid'],
  mixins: [Domain, mixinCommon, mixinRates, mixinOrder],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Delivery Rates",
          href: "#",
          active: true
        }
      ],
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      storeListData: [],
      deliveryCityDispatchList: [],
      provincelist: [],
      packsizeArray: [
        {id: 1, text: 'Small'},
        {id: 2, text: 'Medium'},
        {id: 3, text: 'Large'}
      ]
    }
  },
  watch: {
    searchKey: function(val) {
      this.searchCustomers(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchCustomers(this.searchKey);
    }
  },
  created(){
    this.handleBreadcrumb(); 
    this.handleProvince();
    this.handleGetCityDispatchList();
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    handleProvince: async function() {
      try {
          let response = await this.getProvince("5d8b9e5dfb195816aaea61b3"); 
          this.provincelist = response; 
              
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }
    },
    filterProviceName: function(id){
      var provin =  this.provincelist.filter(function(province) {         
        return province._id === id;
      });

      if(provin.length > 0){
        return provin[0].province_name;
      } else {
        return "";
      }
      
    },
    filterPackageSize: function(id){
      var size =  this.packsizeArray.filter(function(packsize) {         
        return packsize.id === id;
      });

      if(size.length > 0){
        return size[0].text;
      } else {
        return "";
      }
      
    },
    statusToText: function(status){      
      if(status === 1){
        return "Active";
      } else {
        return "Deactive";
      }      
    },
    handleGetCityDispatchList: async function() {
        var statusType = "readyToPick";
        try {
          
          let response = await this.getCityDeliveryDispatchList(statusType,this.relcityid); 
          this.deliveryCityDispatchList = response;
          this.searchCustomers();
             
      } catch (error) {   
            this.$swal.fire({
            position: 'center',
            type: 'error',
            title: 'Error',
            showConfirmButton: false,
            timer: 1500
            }); 
      }
    },
    searchCustomers: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.tableItems = this.deliveryCityDispatchList.slice(0, pagesize);
        this.rowcount = this.deliveryCityDispatchList.length;
      } else {
        this.tableItems = this.deliveryCityDispatchList
          .filter(obj => {
            {
              return (
                (obj._id.city_name &&
                  obj._id.city_name.toLowerCase().includes(keyword.toLowerCase())) 
              );
            }
          })
          .slice(0, pagesize);

          this.rowcount = this.deliveryCityDispatchList.length;
      }
      
    },   
    pagination: function(pagesize, currentPage) {
      
      let pagesizeint = parseInt(this.pageSize);

      let endsize = pagesize * currentPage;
      let startsize = 0;

      if(currentPage === 1){
        startsize = 0;
      } else {
        startsize = endsize - (pagesize) ;
      }     

      this.tableItems = this.deliveryCityDispatchList.slice(startsize, endsize);

    }, 
    viewCityOrders(cityId,cityName,pendingCount,deliveredCount){
        alert(cityId);
        alert(pendingCount);
        alert(deliveredCount);
        this.$router.push({
           name: "Deliver Dispatch City", 
           params: { 
               id: cityId,
               city_name: cityName,
               ready_to_pick: pendingCount,
               delivered: deliveredCount
            } 
        });
    },
    pushEdit(pid) {
      this.$router.push({ name: "Delivery Rate Configure", params: { id: pid } });
    },
    handleCreate: function() {
      this.$router.push({ name: "Delivery Rate Configure", params: { id: 0 } });
    },
    pushDelete: async function(id) {
      let self = this;
      this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            self.DeleteRate(id);
          }
        })
    },
    DeleteRate: async function(id) {
      try {
          let response = await this.deleteDeliveryRate(id);
          if(response.success){
            if(response.success === 1){
              this.$swal.fire({
                    position: 'center',
                    type: 'success',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
            } else {
              this.$swal.fire({
                    position: 'center',
                    type: 'error',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 1500
                });
            }
          }
          this.handleGetRatesList();
          this.searchCustomers(""); 
              
      } catch (error) {   
        throw error; 
      }
    },
    viewOrder(orderId) {
        this.$router.push({ path: '/deliver-order-details/'+orderId});    
    },
    printOrder: async function(orderId){
        try {
        let res = await this.printOrderDetails(orderId);
        Domain.APP_DOMAIN = Domain.APP_DOMAIN.split("api/v1/")[0];
        window.open(Domain.APP_DOMAIN + res.file);
      } catch (error) {
        throw error;
      }
    },
    viewDispatchAll(){
        this.$router.push({ path: '/deliver-dispatch'});
    }
  }, 
}

</script>
<style>
#container {
	height: 300px;
	min-width: 310px;
	max-width: 800px;
    margin: 0 auto;
}
.tbl-container{
  padding-left: 15px;
  padding-right: 15px;
}
#button-bar {
    min-width: 310px;
    max-width: 800px;
    margin: 0 auto;
}
.delprof-sbtn{
    background: #ffd338;
    color: #000000;
    border: none;
}
.delprof-sbtn:hover{
    color: #ffffff;
}
.delstatus-badge{
    padding: 3px;
    background:  #ffd300;
    color: #ffffff;
    border-radius: 4px;
    font-size: 9px;
}
.go-back-btn{
    text-align: right;
    margin-top: 30px;
}
.user-det-name,.date-block,.city-block{
    color: #808080;
    font-size: 10px;
}
.user-order-left{
    padding-left: 0px;
    padding-right: 30px;
    text-align: left;
}
.user-order-right{
    padding-left: 50px;
    padding-right: 0px;
    text-align: left;
}
.bold-text-cont{
    font-size: 14px;
    font-weight: 600;
    color: #3a3a3a;
}
@media screen and (max-width: 1290px){
  .tbl-container{
    width: 100%;
    overflow-x: scroll;
  }
}
</style>