import Domain from "../domain";
export default {
  methods: {
    getDownlordCitySheet: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "common/country/city/list/download",
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  }
};
