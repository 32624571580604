<template>
<div>
  <div class="row" id="delPartReg" style="margin-top:20px;background: #ffffff;">
    <div id="content" class="col-sm-12">
      <h2 class="title">Delivery Partner Register Account</h2>
      <p>If you already have an account with us, please login at the <a @click="handleLogin">login page</a>.</p>
      <form  class="form-horizontal account-register clearfix" @submit="checkForm">
        <fieldset id="account">
          <legend>Your Personal Details</legend>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="input-firstname">First Name</label>
            <div class="col-sm-10">
              <input type="text"  :style="styleValidFname" required v-model="form.first_name"  name="firstname" value="" placeholder="First Name" id="input-firstname" class="form-control">
            </div>
          </div>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="input-middlename">Middle Name</label>
            <div class="col-sm-10">
              <input :style="styleValidMname" type="text" required v-model="form.middle_name"  name="middlename" value="" placeholder="Middle Name" id="input-middlename" class="form-control">
            </div>
          </div>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="input-lastname">Last Name</label>
            <div class="col-sm-10">
              <input :style="styleValidLname" type="text" required v-model="form.last_name" name="lastname" value="" placeholder="Last Name" id="input-lastname" class="form-control">
            </div>
          </div>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="input-email">E-Mail</label>
            <div class="col-sm-10">
              <input type="email" :style="changeColor1" @input="validateEmail(form.email)" required v-model="form.email"  name="email" value="" placeholder="E-Mail" id="input-email" class="form-control">
            </div>
          </div>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="input-mobile">Mobile</label>
            <div class="col-sm-10">
              <VuePhoneNumberInput @input="validatePhoneNO(mobile)" v-model="mobile" default-country-code="LK" no-example @update="setPhoneNo" id="mobileInputDel"/>
              <!-- <input type="number" required v-model="form.mobile" name="mobile" value="" placeholder="Mobile" id="input-mobile" class="form-control"> -->
            </div>
          </div>
          <div class="form-group">
            <label class="col-sm-2 control-label" for="input-mobile-optional">Fixed line number (optional)</label>
            <div class="col-sm-10">
              <input type="number" v-model="form.fixed_line_number" name="mobile" value="" placeholder="Fixed line number" id="input-mobile-optional" class="form-control">
            </div>
          </div>
        </fieldset>
        <fieldset>
          <legend>Your Password</legend>
          <div class="form-group required input-password">
            <label class="col-sm-2 control-label" for="password">Password</label>
            <div class="col-sm-10">
              <input oncopy="return false" onpaste="return false" :style="styleValidPassword" type="password" required v-model="form.password" name="password" placeholder="Password" id="password" class="form-control" @input="valPassword()" ref="inputRegPwdDp">
              <span class="toggleIcon" @click="togglePwd()" ref="toggleIcon"><i class="fa fa-eye"></i></span>
              <!-- <div class="error-message" v-show="!passwordValid && form.password!=''">At least 8 characters, include one lowercase and one uppercase letter</div> -->
              <div v-show="!passwordValid && form.password !=''">
                <span style="color: grey;font-size:10px;">
                    <span v-for="(vrule,index) in valRuleArray" :key="vrule.index">
                        {{vrule.valRule}} <span v-html="vrule.valIcon"></span>{{valRuleArray.length-1!=index?', ':''}} 
                    </span>
                </span> 
              </div>
            </div>
          </div>
          <div class="form-group required">
            <label class="col-sm-2 control-label" for="password_two">Confirm Password</label>
            <div class="col-sm-10">
              <input oncopy="return false" onpaste="return false" :style="styleValidConfPassword" type="password" v-model="confirmPassword" placeholder="Verify Password" id="password_two" class="form-control" :disabled="form.password==''" @input="valConfPassword()">
              <!-- <div class="error-message" v-show="!confPasswordValid && confirmPassword!=''">At least 8 characters, include one lowercase and one uppercase letter</div> -->
              <div v-show="!confPasswordValid && confirmPassword !=''">
                <span style="color: grey;font-size:10px;">
                    <span v-for="(vrule,index) in valRuleArrayConf" :key="vrule.index">
                        {{vrule.valRule}} <span v-html="vrule.valIcon"></span>{{valRuleArrayConf.length-1!=index?', ':''}} 
                    </span>
                </span> 
              </div>
              <!-- <div class="error-message" v-show="passwordMissMatched && confirmPassword!=''">Password missmatched</div> -->
            </div>
          </div>
        </fieldset>
        <div class="buttons">
          <!-- <b-row v-if="showReCapture === formIsValid">
            <b-col md="12">
              <vue-recaptcha class="pull-right" @verify="verifyEvent" sitekey="6Ldn8sYUAAAAAHeNiLb65O40B511b8PtFPA4fYLY" :loadRecaptchaScript="true"></vue-recaptcha>
            </b-col>
          </b-row> -->
          <b-row style="margin-top: 12px;">
            <b-col md="12">
              <div class="pull-right">
               <span style="display: inline-block;margin-right: 15px;line-hegith: 15px;vertical-align: top;"> I have read and agree to the <a href="#" class="agree"><b>Terms and Conditions</b></a></span>
                <input class="box-checkbox" required type="checkbox" name="agree" v-model="form.agree" style="margin-right: 15px">
                <!-- <input  type="submit" value="Continue" class="btn btn-primary"> -->
                <!-- <input :disabled="disable" type="submit" value="Continue" class="btn btn-primary"> -->
                <input type="submit" value="Continue" class="btn delpartreg-btn" :disabled="!formIsValid">
              </div>
            </b-col>
          </b-row>
        </div>
      </form>
    </div>
  </div>
</div>
</template>
<script src="https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit" async defer>
</script>
<script>
import mixinAuth from "@/mixins/deliver/APIAuth";
import VueRecaptcha from 'vue-recaptcha';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
export default {
  name: 'deliverRegister',
  mixins: [mixinAuth],
  components: { VueRecaptcha,
  VuePhoneNumberInput },
  data(){
      return{
        changeColor1: {
          border: "1px solid #ccc"
        },
        emailValid: false,
        showReCapture: true,
        disable: true,
        mobile: "",
        confirmPassword: "",
        form: {
          first_name: "",
          middle_name: "",
          last_name: "",
          email:"",
          mobile: "",
          fixed_line_number: "",
          password: "",
          agree: false
        },
        passwordValid: false,
        confPasswordValid: false,
        passwordMissMatched: false,
        valRuleArray: [
          {
              valRule: 'At least 8 characters',
              valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
          },
          {
              valRule: 'One lowercase letter',
              valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
          },
          {
              valRule: 'One uppercase letter',
              valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
          },
          {
              valRule: 'One special character',
              valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
          }
        ],
        valRuleArrayConf: [
          {
              valRule: 'At least 8 characters',
              valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
          },
          {
              valRule: 'One lowercase letter',
              valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
          },
          {
              valRule: 'One uppercase letter',
              valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
          },
          {
              valRule: 'One special character',
              valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
          },
          {
              valRule: 'Match password',
              valIcon: '<i style="color: red" class="fa fa-times-circle"></i>'
          }
        ]
      }
  },
  watch:{
    $route (to, from){
        this.menuUnLock(); 
    },
    $store (){
        this.menuUnLock(); 
    },
    form: {
        handler(val){
          if(val.password==""){
            this.confirmPassword = "";
          } 
        },
        deep: true
    },
    confirmPassword: function(val) {
        if(val!=this.form.password){
            this.passwordMissMatched = true;
        }else{
            this.passwordMissMatched = false;
        }
    }
  },
  created(){
    this.menuUnLock(); 
  },
  mounted(){
        this.$nextTick(function () { 
               const mobileInputDel = document.querySelector("#mobileInputDel");
               mobileInputDel.style.boxShadow = "none";
        });

  },
  computed: {
    formIsValid() {
      return (
        this.form.first_name &&
        this.form.middle_name &&
        this.form.last_name &&
        this.form.email &&
        this.emailValid &&
        this.form.mobile &&
        this.mobileValid &&
        this.form.password &&
        this.form.agree &&
        this.passwordValid &&
        this.confPasswordValid
      );
    },
    styleValidPassword(){
        var pattOne = /(?=.*[a-z])/;
        var pattTwo = /(?=.*[A-Z])/;
        var pattThree = /(?=.*\W)/;
        let style=""
        if(this.form.password==""){
            style = "border: 1px solid #ccc !important;";
        } else {
            if(this.form.password.length < 8){
                style = "border: 1px solid red !important;";
            } 
            if(!pattOne.test(this.form.password)){
                style = "border: 1px solid red !important;";
            }
            if(!pattTwo.test(this.form.password)){
                style = "border: 1px solid red !important;";
            }   
            if(!pattThree.test(this.form.password)){
                style = "border: 1px solid red !important;";
            }   
            if(pattThree.test(this.form.password) && pattTwo.test(this.form.password) &&
                pattOne.test(this.form.password) && this.form.password.length >= 8
                )
            {
                style = "border: 1px solid green !important;";
            }          
                
        }
        return style;
    },
    styleValidConfPassword(){
        var pattOne = /(?=.*[a-z])/;
        var pattTwo = /(?=.*[A-Z])/;
        var pattThree = /(?=.*\W)/;
        let style=""
        if(this.confirmPassword==""){
            style = "border: 1px solid #ccc !important;";
        } else {
            if(this.confirmPassword.length < 8){
                style = "border: 1px solid red !important;";
            } 
            if(!pattOne.test(this.confirmPassword)){
                style = "border: 1px solid red !important;";
            }
            if(!pattTwo.test(this.confirmPassword)){
                style = "border: 1px solid red !important;";
            }   
            if(!pattThree.test(this.confirmPassword)){
                style = "border: 1px solid red !important;";
            }   
            if(this.form.password!=this.confirmPassword){
                style = "border: 1px solid red !important;";
            }   
            if(pattThree.test(this.confirmPassword) && pattTwo.test(this.confirmPassword) &&
                pattOne.test(this.confirmPassword) && this.confirmPassword.length >= 8 &&
                this.form.password==this.confirmPassword
                )
            {
                style = "border: 1px solid green !important;";
            }          
        }
        return style;
    },
    styleValidLname(){
        let style=""
        if(!this.form.last_name){
              style = "border: 1px solid red !important;";
        } else{
            style = "border: 1px solid green !important;";
        }
        if(this.form.last_name==""){
            style = "border: 1px solid #ccc !important;";
        }
        return style;
    },
    styleValidMname(){
        let style=""
        if(!this.form.middle_name){
              style = "border: 1px solid red !important;";
        } else{
            style = "border: 1px solid green !important;";
        }
        if(this.form.middle_name==""){
            style = "border: 1px solid #ccc !important;";
        }
        return style;
    },
    styleValidFname(){
        let style=""
        if(!this.form.first_name){
              style = "border: 1px solid red !important;";
        } else{
            style = "border: 1px solid green !important;";
        }
        if(this.form.first_name==""){
            style = "border: 1px solid #ccc !important;";
        }
        return style;
    },
  },
  methods:{
    validateEmail(str) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (re.test(String(str).toLowerCase()) === true) { 
            this.changeColor1.border = "1px solid green";
            this.emailValid = true;
        } else {
            this.changeColor1.border = "1px solid red";
            this.emailValid = false;
        }
        if (str === "") {
            this.changeColor1.border = "1px solid #ccc";
        }
    },
    validatePhoneNO(key) {
      if(key === "0"){
          this.form.mobile = ""
      }
      key = key.replace(/^0+/, '')
      key = key.replace(/ /g, "");
      this.form.mobile = key;
      if (key.length == 9) {
          this.mobileValid = true;
      } else if (key.length === 10 || key.length < 10) {
          this.mobileValid = false;
      }
  },
  valConfPassword: function() {
      var pattOne = /(?=.*[a-z])/;
      var pattTwo = /(?=.*[A-Z])/;
      var pattThree = /(?=.*\W)/;
      // var pattFour = /((?=.*[a-z])(?=.*[A-Z]).{8})/;

      if(pattOne.test(this.confirmPassword)==true){
          this.valRuleArrayConf[1].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
      }else{
          this.valRuleArrayConf[1].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if(pattTwo.test(this.confirmPassword)==true){
          this.valRuleArrayConf[2].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
      }else{
          this.valRuleArrayConf[2].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if(pattThree.test(this.confirmPassword)==true){
          this.valRuleArrayConf[3].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
      }else{
          this.valRuleArrayConf[3].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if((this.confirmPassword.length >= 8)==true){
          
          this.valRuleArrayConf[0].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
      }else{
          this.valRuleArrayConf[0].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if((this.form.password===this.confirmPassword)){
          
          this.valRuleArrayConf[4].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
      }else{
          this.valRuleArrayConf[4].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
      }
      if(pattOne.test(this.confirmPassword)==true && pattTwo.test(this.confirmPassword)==true && pattThree.test(this.confirmPassword)==true && this.confirmPassword.length >= 8 && this.form.password==this.confirmPassword){
          this.confPasswordValid = true;
      }else{
          this.confPasswordValid = false;
      }
  }, 
  menuUnLock(){
    if(document.body.classList.contains('common-home')){ 
        document.querySelector('body').classList.remove('common-home');
    }
  },
  verifyEvent(val) {
    if (val !== undefined && val !== "") {
      this.disable = false;
    } else {
      this.disable = true;
    };
  },
  setPhoneNo(payload){
    this.form.mobile = payload.formattedNumber;
  },
  handleLogin(){
    this.$router.push({ name :'Delivery Partner login'});
  },
  handleContinue: function(id, regobj) {
    this.$router.push({
      name: "Deliver OTP",
      params: { object: regobj, _id: id }
    });
  },
  checkForm: async function (e) {   
    e.preventDefault();
    try {
        let status = await this.deliverRegister(this.form); 
          if(status.data._id !== ""){
            this.$swal.fire({
                position: 'top-right',
                type: 'success',
                title: 'Successfully Registered!',
                showConfirmButton: false,
                timer: 1500
              }) 
            this.handleContinue(status.data._id, status.data);
          }
            
    } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })
        if(error.data.code === 409){
            // this.handleLogin();
        }        
    }
  },
  valPassword: function() {
    var pattOne = /(?=.*[a-z])/;
    var pattTwo = /(?=.*[A-Z])/;
    var pattThree = /(?=.*\W)/;
    // var pattFour = /((?=.*[a-z])(?=.*[A-Z]).{8})/;

    if(pattOne.test(this.form.password)==true){
        this.valRuleArray[1].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
    }else{
        this.valRuleArray[1].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
    }
    if(pattTwo.test(this.form.password)==true){
        this.valRuleArray[2].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
    }else{
        this.valRuleArray[2].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
    }
    if(pattThree.test(this.form.password)==true){
        this.valRuleArray[3].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
    }else{
        this.valRuleArray[3].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
    }
    if((this.form.password.length >= 8)==true){
        
        this.valRuleArray[0].valIcon = '<i style="color: green" class="fa fa-check-circle"></i>';
    }else{
        this.valRuleArray[0].valIcon = '<i style="color: red" class="fa fa-times-circle"></i>';
    }
    if(pattOne.test(this.form.password)==true && pattTwo.test(this.form.password)==true && pattThree.test(this.form.password)==true && this.form.password.length >= 8){
        this.passwordValid = true;
    }else{
        this.passwordValid = false;
    }
  },
  togglePwd: function() {
    if(this.$refs.inputRegPwdDp.type==="password"){
        this.$refs.inputRegPwdDp.type = "text";
        this.$refs.toggleIcon.innerHTML = `<i class="fa fa-eye-slash"></i>`;
    }else{
        this.$refs.inputRegPwdDp.type = "password";
        this.$refs.toggleIcon.innerHTML = `<i class="fa fa-eye"></i>`;
    }
  }
  }
}
</script>
<style>
.country-selector__label {
  display: none !important;
}
.error-message{
  margin-top: 10px;
  color: red;
}
#delPartReg .input-password input[type="password"]{
  position: relative;
}
#delPartReg .input-password span.toggleIcon{
  position: relative;
  display: block;
  float: right;
  top: -31px;
  right: 7px;
  cursor: pointer;
}
#delPartReg .input-password .fa{
  font-size: 15px;
}
.delpartreg-btn{
  background: #ffd338;
  color: #000000;
  border: none;
}
.delpartreg-btn:hover{
  color: #ffffff;
}
</style>