<template>
    <div>
        <p class="lead">Bank Details</p>
        <div class="row">       
            <div class="col-sm-12">
                
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Name</label>
                            <input type="text" required class="form-control" placeholder="Name" v-model="bankAccount.account_name">
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Account Number</label>
                            <input type="text" required class="form-control" placeholder="Account Number" v-model="bankAccount.account_number">
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Bank</label>
                            <input type="text" required class="form-control" placeholder="Bank" v-model="bankAccount.bank">
                        </div>
                    </div>
                </div>
                
                <div class="row">
                    <div class="col-sm-4">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Branch</label>
                            <input type="text" required class="form-control" placeholder="Branch" v-model="bankAccount.branch">
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-12" style="margin-top: 30px;">
                        <div class="form-group text-right">
                            <button class="btn delprof-sbtn" @click="haddleAccount()" >Submit</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import mixinProfile from "../../../mixins/deliver/APIProfile";
export default {
  mixins: [mixinProfile],
  data: function () {
    return {
        bankAccount: {}
    }
   },
   created() {
      this.getPersanalInfo();
   },
   methods: {
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        this.bankAccount = response;
      } catch (error) {
        console.log(error);
      }
    },
    haddleAccount: async function() {
      try {
        await this.putProfileBacicInfo(this.bankAccount);
        this.$swal.fire({
          position: 'center',
          type: 'success',
          title: 'success submit account details',
          showConfirmButton: false,
          timer: 1500
        })
      } catch (error) {
        console.log(error);
      }
    }
   }
}
</script>
<style>
.delprof-sbtn{
    background: #ffd338;
    color: #000000;
}

</style>
