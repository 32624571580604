<template>
    <div>
        <div id="table-content" class="col-sm-12" style="padding-right: 0px; padding-left: 0px;">
            <table id="example" class="table table-striped table-border-out">
                <thead>
                    <tr>
                        <th scope="col" class="head-table col-md-6">Order Id</th>
                        <th scope="col" class="head-table col-md-4">Status</th>
                        <th scope="col" class="head-table col-md-2">Action</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-if="tableItems.length === 0">
                        <td />
                        <td style="vertical-align: middle; text-align: left;">No Records</td>
                        <td />
                    </tr>
                    <tr v-for="(itemp, index) in tableItems" :key="index">
                        <td class="text-left col-md-4" >
                            <div class="row">
                                <div class="col-md-12" style="font-size:14px;">								
                                    <p>
                                        <b>{{ itemp.id }}</b>
                                    </p>
                                </div>
                            </div>		
                            <div class="row">
                                <div class="col-md-12 col-sm-12">								
                                    Order Date : {{ itemp.date }}
                                </div>
                            </div>
                        </td>
                        <td class="text-center col-md-2" style="vertical-align: middle; text-align: left;" >
                            <span class="badge" style="border-radius: 4px; font-size: 10px; padding: 4px 6px;" :style="itemp.colors"><B> {{ itemp.status }} </B></span>
                        </td>
                        <td style="vertical-align: middle; text-align: left;">
                            <button type="button" data-toggle="tooltip" title="" style="background: #ffd338;" class="btn merchprof-sbtn" onclick="" data-original-title="View" @click="handdleChange(itemp)">View</button>
                        </td>
                    </tr>
                    <tr v-if="tableItems.length !== 0">
                        <td />
                        <td />
                        <td style="padding-bottom: 20px; padding-top: 15px; padding-right: 20px; vertical-align: middle; text-align: left;">
                            <a style="border-bottom: 1px solid; float: right;">View All</a>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>
<script>
  export default {
    data() {
      return {
        tableItems: [
            {id: 132154454891, date: "2020-04-03", status: "pending", colors: {background: "#ffd338"}},
            {id: 132154541721, date: "2020-04-03", status: "Ready To Pickup", colors: {background: "#3B3B98"}},
            {id: 100210138111, date: "2020-04-03", status: "Shipped", colors: {background: "#27ae60"}},
            {id: 369821558752, date: "2020-04-03", status: "Pending", colors: {background: "#ffd338"}},
            {id: 132459876145, date: "2020-04-03", status: "Delivered", colors: {background: "#27ae60"}},
            {id: 980154541721, date: "2020-04-03", status: "Ready To Pickup", colors: {background: "#3B3B98"}},
            {id: 789465132850, date: "2020-04-03", status: "Canceled", colors: {background: "#ee5253"}}
        ]
      }
    }
  }
</script>