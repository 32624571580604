<template>
<div class="classWell deliverrates" style=""> 
  <div class="panel-body row">
    <div class="col-sm-9" style="padding-left:0px;">
      <div class="input-group col-sm-3" style="padding-left:15px;">
        <!-- <input type="text" v-model="searchKey" class="form-control" id="input-store" placeholder="Search Rates" value="" name="voucher">           -->
      </div>
    </div>
    <div class="col-sm-3" style="padding-right:15px;">
      <button v-if="this.tableItems.length === 0" type="button" class="btn delprof-sbtn" style="float: right;" @click="handleCreate">
        Add New
      </button>
    </div>
  </div>  
  <h2 class="title">Delivery Rates</h2>
  <div class="row tbl-container">
    <table id="example" class="table table-striped table-bordered" >
      <thead>
        <tr>
          <th scope="col">Zone</th>
          <th scope="col">Standard Weight (kg)</th>
          <th scope="col">Standard Rate (LKR)</th>
          <th scope="col">Additional Weight (kg)</th>
          <th scope="col">Additional Rate (LKR)</th>
          <th scope="col">Status</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(column, index) in tableItems" :key="index">
          <td class="text-center"><h4>{{ column.zone_one }}</h4></td>
          <td class="text-center"><h4>{{ column.standard_weight }}</h4></td>
          <td class="text-center"><h4>{{ column.standard_rate }}</h4></td>
          <td class="text-center"><h4>{{ column.additional_kg_weight }}</h4></td>
          <td class="text-center"><h4>{{ column.additional_kg_price }}</h4></td>
          <td class="text-center"><h4>{{ column.active_status === 1? "Active" : "Deactive" }}</h4></td>
          <td class="product-action-style text-center">
            <!-- <button type="button" class="btn btn-sm btn-primary">Simple Product</button>&nbsp; -->
            <button type="button" @click="pushEdit(column._id)" class="btn btn-sm btn-primary">Edit</button>&nbsp;
            <!-- <button type="button" @click="pushDelete(column._id)" class="btn btn-sm btn-danger">Delete</button> -->
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row text-right">
    <div class="col-sm-12">
        <div class="mt-3">
      <b-pagination 
      v-model="currentPage" 
      :per-page="pageSize"
      :total-rows="rowcount" 
      align="right"></b-pagination>
    </div>  

    </div>
    
  </div>
  

</div> 
</template>
<script> 
import mixinCommon from "@/mixins/APICommon";
import mixinRates from "@/mixins/deliver/APIRates";
import { async } from 'q';
import { functions } from 'firebase';
export default {
  components: { 
  },
  mixins: [mixinCommon, mixinRates],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Delivery Rates",
          href: "#",
          active: true
        }
      ],
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      storeListData: [],
      provincelist: [],
      packsizeArray: [
        {id: 1, text: 'Small'},
        {id: 2, text: 'Medium'},
        {id: 3, text: 'Large'}
      ]
    }
  },
  watch: {
    searchKey: function(val) {
      this.searchCustomers(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchCustomers(this.searchKey);
    }
  },
  created(){
    this.handleBreadcrumb(); 
    this.handleProvince();
    this.handleGetRatesList();
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    handleProvince: async function() {
      try {
          let response = await this.getProvince("5d8b9e5dfb195816aaea61b3"); 
          this.provincelist = response; 
              
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }
    },
    filterProviceName: function(id){
      var provin =  this.provincelist.filter(function(province) {         
        return province._id === id;
      });

      if(provin.length > 0){
        return provin[0].province_name;
      } else {
        return "";
      }
      
    },
    filterPackageSize: function(id){
      var size =  this.packsizeArray.filter(function(packsize) {         
        return packsize.id === id;
      });

      if(size.length > 0){
        return size[0].text;
      } else {
        return "";
      }
      
    },
    statusToText: function(status){      
      if(status === 1){
        return "Active";
      } else {
        return "Deactive";
      }      
    },
    handleGetRatesList: async function() {
      try {
          let response = await this.getDeliveryRates(); 
          this.storeListData = response;
          for (let index = 0; index < this.storeListData.length; index++) {
            // this.storeListData[index].zone_one_text = this.filterProviceName(this.storeListData[index].zone_one);
            // this.storeListData[index].zone_two_text = this.filterProviceName(this.storeListData[index].zone_two); 
            let sizes = '';  
            // for (let indexs = 0; indexs < this.storeListData[index].deliver_product_size.length; indexs++) {
            //   if(indexs !=0 ){
            //     sizes += ', ';
            //   }
              
            //   sizes += this.filterPackageSize(this.storeListData[index].deliver_product_size[indexs]);
               
            // }
            // this.storeListData[index].packsizes_text = sizes;
          }

          this.searchCustomers(""); 
              
      } catch (error) {   

      }
    },
    searchCustomers: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.tableItems = this.storeListData.slice(0, pagesize);
        this.rowcount = this.storeListData.length;
      } else {
        this.tableItems = this.storeListData
          .filter(obj => {
            {
              return (
                (obj.zone_one_text &&
                  obj.zone_one_text.toLowerCase().includes(keyword.toLowerCase())) ||
                (obj.zone_two_text &&
                  obj.zone_two_text.toLowerCase().includes(keyword.toLowerCase())) 
              );
            }
          })
          .slice(0, pagesize);

          this.rowcount = this.storeListData.length;
      }
      
    },   
    pagination: function(pagesize, currentPage) {
      
      let pagesizeint = parseInt(this.pageSize);

      let endsize = pagesize * currentPage;
      let startsize = 0;

      if(currentPage === 1){
        startsize = 0;
      } else {
        startsize = endsize - (pagesize) ;
      }     

      this.tableItems = this.storeListData.slice(startsize, endsize);

    }, 
    pushEdit(pid) {
      this.$router.push({ name: "Delivery Rate Configure", params: { id: pid } });
    },
    handleCreate: function() {
      this.$router.push({ name: "Delivery Rate Configure", params: { id: 0 } });
    },
    pushDelete: async function(id) {
      let self = this;
      this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            self.DeleteRate(id);
          }
        })
    },
    DeleteRate: async function(id) {
      try {
          let response = await this.deleteDeliveryRate(id);
          if(response.success){
            if(response.success === 1){
              this.$swal.fire({
                    position: 'center',
                    type: 'success',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
            } else {
              this.$swal.fire({
                    position: 'center',
                    type: 'error',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
            }
          }
          this.handleGetRatesList();
          this.searchCustomers(""); 
              
      } catch (error) {   
        throw error; 
      }
    }

  }, 
}

</script>
<style>
#container {
	height: 300px;
	min-width: 310px;
	max-width: 800px;
    margin: 0 auto;
}
.tbl-container{
  padding-left: 15px;
  padding-right: 15px;
}
#button-bar {
    min-width: 310px;
    max-width: 800px;
    margin: 0 auto;
}
.deliverrates .delprof-sbtn{
    background: #5779ae !important;
    color: #ffffff;
    border: none;
}
.deliverrates .delprof-sbtn:hover{
    color: #000000 !important;
}
@media screen and (max-width: 1290px){
  .tbl-container{
    width: 100%;
    overflow-x: scroll;
  }
}
</style>