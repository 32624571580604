import { render, staticRenderFns } from "./OrderListGroup.vue?vue&type=template&id=1ef025cc&scoped=true&"
import script from "./OrderListGroup.vue?vue&type=script&lang=js&"
export * from "./OrderListGroup.vue?vue&type=script&lang=js&"
import style0 from "./OrderListGroup.vue?vue&type=style&index=0&id=1ef025cc&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ef025cc",
  null
  
)

export default component.exports