<template>
  <div class="row">
    <div id="content" class="col-sm-12 merchpreference">
      <div class="page-login">
        <div class="account-border">
          <h2>Preferences</h2><br/>
          <div class="row" style="width: 100%;">
            <form action="#" method="post" enctype="multipart/form-data">
              <div class="col-sm-12 customer-login">

                <div class="form-group">
                  <label class="control-label">Min Order Value</label>
                  <input
                    type="text"
                    placeholder="Min  Order Value"
                    class="form-control"
                    v-model="preferences.cod_order_margin"
                    @input="valCodOrderMargin(preferences.cod_order_margin)"
                    :disabled="isShow"
                  />
                </div>

                <hr/>

                <div class="form-group">
                  <label class="control-label">Store Percentage(%)</label>
                  <input
                    type="text"
                    placeholder="Store Percentage(%)"
                    class="form-control"
                    v-model="preferences.store_percentage"
                    :disabled="isShow"
                    @input="valStorePercentage(preferences.store_percentage)"
                  />
                </div>

                <div class="row pull-right" style="padding-bottom: 10px;">
                  <div class="col-sm-12">
                    <button
                      type="button"
                      @click="haddleSubmit()"
                      class="btn btn-sm merchprof-sbtn"
                      v-if="userRole !== 5 && userRole !== 6 && userRole !== 7"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinPreferences from "@/mixins/merchant/APIPreferences";
export default {
  mixins: [mixinPreferences],
  data: function() {
    return {
      userRole: "",
      isShow: false,
      breadcrumb: [
        {
          text: "Preferences",
          href: "",
          active: false,
        },
      ],
      preferences: {
        cod_order_margin: "",
        store_percentage: ""
      }
    };
  },
  created() {
    this.haddleGet();
    this.handleBreadcrumb();
    let mdata = JSON.parse(localStorage.merchant_data);
    this.userRole = mdata.role;
    if (this.userRole !== 5 && this.userRole !== 6 && this.userRole !== 7) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    valStorePercentage(number) {
      number = number.replace(/[^0-9]/g, "");
      this.preferences.store_percentage = number;
    },
    valCodOrderMargin(number) {
      number = number.replace(/[^0-9]/g, "");
      this.preferences.cod_order_margin = number;
    },
    haddleGet: async function() {
      try {
        let res = await this.getPreferences();
        this.preferences.cod_order_margin = res.cod_order_margin;
        this.preferences.store_percentage = res.store_percentage;
      } catch (error) {
        console.log(error);
      }
    },
    haddleSubmit: async function() {
      try {
        let res = await this.postPreferences(this.preferences);
        if (res) {
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "success",
            showConfirmButton: false,
            timer: 1500,
          });
          this.haddleGet();
        }
      } catch (error) {
        console.log(error);
      }
    }
  },
};
</script>
<style scoped>
.merchpreference .merchprof-sbtn {
  background: #5779ae;
  color: #ffffff;
  border: none;
}
.account-border {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
}
.merchpreference .merchprof-sbtn:hover {
  color: #000000;
}
</style>
