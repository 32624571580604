<template>
<div>

  <div class="container">
  <div class="row justify-content-md-center">
    <div class="col-md-4">
    </div>
    <div class="col-md-4 text-center">
        <div class="account-border">
          <div class="row page-login">
              <div class="col-sm-12 merchant-login">
                <div class="">
                  <h2><i class="fa fa-lock" aria-hidden="true"></i>&nbsp;&nbsp;&nbsp; OTP Verification</h2>
                  <p><strong>The OTP code has been sent to your mobile number</strong></p>
                  <p>{{object.mobile}}</p>
                  <div class="form-group">
                    <label class="control-label " for="input-email">Please Enter Your OTP Code - Expires in {{countdownseconds}} seconds</label>
                    <input type="text" required v-model="sentotp" placeholder="Enter OTP Code" class="form-control">
                  </div>
                  <div class="col-md-12 text-center"> 
                      <button class="btn delpartreg-btn pr-1" @click="checkForm" :disabled="!blockresendopt">Submit</button> &nbsp;
                      <button @click="resentOTP()" class="btn delpartreg-btn pl-2" :disabled="blockresendopt">Resent OTP</button>
                  </div>
                </div>
              </div>
          </div>
        </div>
    </div>
    <div class="col-md-4">
    </div>
  </div> 
</div>

  <div class="row">
    <div id="content" class="col-sm-12">
      <div class="page-login">
        
      </div>
    </div>
  </div>   
</div>
</template>
<script>
import mixinAuth from "../../mixins/deliver/APIAuth";
export default {
  name: 'DeliverOTP',
  mixins: [mixinAuth],
  data(){
      return{
        object: {
          mobile:""
        },
        sentotp: '',
        countdownseconds: 0,
        blockresendopt: true
      }
  },
  watch: {
    countdownseconds: function(val) {
      if(val === 0){
        this.blockresendopt = false;
      } else {
        this.blockresendopt = true;
      }
    }
  },
  created() { 
    this.init();
    this.countdownCal(120);
  },
  methods:{
    init(){
      if (this.$route.params.object != undefined) {
        this.object = this.$route.params.object;
      } else {
        this.object.mobile= "";
      }
    },
    countdownCal(duration){
       this.countdownseconds = duration;
       let self = this;
        var downloadTimer = setInterval(function(){
          
          self.countdownseconds -= 1;
          if(self.countdownseconds <= 0){
            clearInterval(downloadTimer);
          }
          
        }, 1000);
        
    },
    resentOTP: async function() {
      try{
        let res = await this.deliverMobileVerifyResend(this.object._id);
        this.$swal.fire({
          position: 'center',
          type: 'success',
          title: 'Successfully resent OTP',
          showConfirmButton: false,
          timer: 1500
        });
        this.countdownCal(120);
      }catch(error){
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: "Failed to resent OTP",
          showConfirmButton: false,
          timer: 2000
        });
      }
    },
    register(){
        this.$router.push({name: 'Delivery Partner register'});
    },
    handleLogin(){
      this.$router.push({name: 'Delivery Partner login'});
    },
    checkForm: async function () {
      try {
          let obj = {
            code: this.sentotp,
            _id: this.object._id
          } 
          let res = await this.deliverMobileVerify(obj);
          this.handleLogin();
            this.$swal.fire({
              position: 'center',
              type: 'success',
              title: "Successfully verified OTP!",
              showConfirmButton: false,
              timer: 2000
            });
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: 'Invalid OTP - ' + error.data.message,
          showConfirmButton: false,
          timer: 2000
        })      
      }

      if(res.status == 409){
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: 'Wrong OTP Code! Please try again.',
          showConfirmButton: false,
          timer: 1500
        })
      }

    }
  }
}
</script>
<style>
.delpartreg-btn{
  background: #ffd338;
  color: #000000;
  border: none;
}
.delpartreg-btn:hover{
  color: #ffffff;
}
</style>