import Domain from "../domain";
export default {
  methods: {
    getDeliveryRates: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "deliver/profile/rates/details",
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getDeliveryRateByID: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "deliver/profile/rates/details/"+id,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    postDeliveryRates: async function(obj) {
      console.log("sfsfsf", obj);
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "deliver/profile/rates",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putDeliveryRates: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "deliver/profile/rates",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    deleteDeliveryRate: async function(id) {
      try {
        let response = await this.axios.delete(
          Domain.APP_DOMAIN + "deliver/profile/rates/"+id,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    }
  }
};
