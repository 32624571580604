import Domain from "../domain";
export default {
  methods: {
    getProductList: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "portal/product/details/",
          Domain.APP_BUYER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
      postCartProducts: async function(cartProductArray) { 
        try {
          let response = await this.axios.post(
            Domain.APP_DOMAIN + "portal/product/set/details",
            cartProductArray
          );
          return response.data;
        } catch (error) {
          throw error;
        }
      },
      saveWishliat: async function(wishlistItem) { 
        try {
          let response = await this.axios.post( Domain.APP_DOMAIN + "portal/wishlist/product", wishlistItem, Domain.APP_BUYER_HEADER );
          return response.data;
        } catch (error) {
          throw error;
        }
      },
      getWishList: async function() {
        try {
          let response = await this.axios.get( Domain.APP_DOMAIN + "portal/wishlist/product/details", Domain.APP_BUYER_HEADER );
          return response.data;
        } catch (error) {
          throw error;
        }
      },
      getProductInfo: async function(id) {
        try {
          let response = await this.axios.get( Domain.APP_DOMAIN + "portal/product/overview/"+id, Domain.APP_BUYER_HEADER );
          return response.data;
        } catch (error) {
          throw error;
        }
      },
      haddleGetDeliveryFailReasonList: async function() {
        try {
          let response = await this.axios.get(
            Domain.APP_DOMAIN + "deliver/order/delivery/faild/reasons",
            Domain.APP_DELIVER_HEADER
          );
          return response.data;
        } catch (error) {
          throw error;
        }
      }
  }
};
