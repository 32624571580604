<template>
	<div class="row">
			<div id="content" class="col-sm-12">
				<div class="page-login">
				
					<div class="account-border">
          <div class="well content-inner content-inner-order-details">										
                
						<div class="row">
              
							<div class="col-sm-6 customer-login">
								<h2>Order Details</h2>
              </div>
							<div class="col-sm-6 customer-login" style="text-align: -webkit-right;">
                <button type="button" class="btn delord-print-btn">Print</button>
              </div>
            </div>
            <hr/>

            <div class="row">
              
							<div class="col-sm-4">
								<img width="100px" :src="order.simple_product_id.images[0].image" alt="Aspire Ultrabook Laptop" title="Aspire Ultrabook Laptop" class="img-thumbnail">             

                <ul class="product-options clearfix">
                  <!-- <li class="radio active">
                    <h4><b>{{ order.simple_product_id.product_id.product_name }} / {{ order.simple_product_id.attributes[0].attribute_value_id.lable }} / {{  order.simple_product_id.attributes[0].attribute_value_id.lable }}</b></h4>
                  </li> -->

					      	<!-- <p class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;30 Days Buyer Protection</p> -->
			      			<p class="p-shipping-details">
                    <img src="@/assets/image/product/logistics-delivery-truck-in-movement.jpg" style="width:28px;" />
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Delivery Status : <span class="badge"
                    style="border-radius: 4px; font-size: 10px; padding: 4px 6px;" 
                    :style=" order.order_line_status === 'Cancelled' ? 'background: rgb(225, 83, 55);' : 
                      order.order_line_status === 'Pending'  ? 'background: rgb(254, 205, 54); color: white;':  
                      order.order_line_status === 'Processing' ? 'background: rgb(254, 205, 54); color: white;':  
                      order.order_line_status === 'Ready to Pickup' ? 'background: rgb(0, 91, 170); color: white;':  
                      order.order_line_status === 'Shipped' ? 'background: rgb(77, 161, 48); color: white;':  
                      order.order_line_status === 'Delivery Confirmed' ? 'background: #17c017; color: white;' : 
                      order.order_line_status === 'Delivered' ? 'background: rgb(77, 161, 48); color: white;':  
                      order.order_line_status === 'Fail to Delivered' ? 'background: rgb(225, 83, 55); color: white;':  
                      order.order_line_status === 'Return to Store' ? 'background: rgb(0, 91, 170); color: white;' : 
                      'background: rgb(0, 91, 170); color: white;'"> {{ order.order_line_status}} </span> 
                  </p>
					      	<p class="p-shipping-details"><img src="@/assets/image/product/money.jpg" style="width:25px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    Payment Method:{{ 
                        paymentType==1 ? " Supiri Pola Credit" : 
                        paymentType==2 ? " Cash On Delivery":  
                        paymentType==3 ? " Bank Transfer" : 
                        paymentType==4 ? " Card Payment": ""
                    }}
                  </p>

                </ul>
                
              </div>
              
              <div class="col-sm-4">
                <!-- <div class="row">
                  <div class="col-sm-12">
                    <h4>Buyer Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
                        <p>Name : {{ order.order_id.user_id.first_name}} {{ order.order_id.user_id.last_name}}</p>
                      </li>
                      <li class="radio active">
                        <p>Mobile : {{ order.order_id.user_id.mobile}}</p>                    
                      </li>
                      <li class="radio active">
                        <p>Email : {{ order.order_id.user_id.email}}</p>                                        
                      </li>
                    </ul>

                  </div>
                </div>
                <hr/> -->
                <div class="row">
                  <div class="col-sm-12">
                    <h4>Merchant Partner Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
                        <p>Name : {{ order.merchant_id.first_name}} {{ order.merchant_id.last_name}}</p>
                      </li>
                      <li class="radio active">
                        <p>Mobile : {{ order.merchant_id.mobile}}</p>                    
                      </li>
                      <li class="radio active">
                        <p>Email : {{ order.merchant_id.email}}</p>                                        
                      </li>
                    </ul>

                  </div>
                </div>  
              </div>

              <div class="col-sm-4">
                <div class="row">
                  <div class="col-sm-12">
                     <h4>Delivery Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
                        <p>Order Number : {{ order.order_id.order_env_number}}</p>
                      </li>
                      <li class="radio active">
                        <p>Billing Name : {{ order.order_id.billing_name}}</p>
                      </li>
                      <li class="radio active">
                        <p>Mobile : {{ order.order_id.billing_mobile}}</p>                    
                      </li>
                      <li class="radio active">
                        <p>Address : {{ order.order_id.address_id.address_line_1}}, {{ order.order_id.address_id.address_line_2}}, {{ order.order_id.address_id.province.province_name}}, {{ order.order_id.address_id.city.city_name}}, {{ order.order_id.address_id.country.country_name}}</p>                                        
                      </li>
                      <li class="radio active">
                        <p>Date : {{ order.order_id.date}}</p>                                        
                      </li>
                    </ul>
                  </div>
                </div>
                <!-- <hr/> -->
                <div class="row" v-if="order.order_line_status === 'Shipped' || order.order_line_status === 'Fail to Delivered'">
                  <div class="col-sm-12">
                    <h4>Action</h4>
                    <ul class="product-options clearfix">
                      <li class="radio active" v-if="order.order_line_status === 'Shipped'">
                        <button type="button" style="background-color: green; color: white;" class="btn btn-outline-primary" @click="changeTrackOrder('Delivered')">Delivered</button>&nbsp;&nbsp;&nbsp;
                        <button type="button" style="background-color: red; color: white;" class="btn btn-outline-primary" @click="changeTrackOrder('Fail to Delivered')">Fail to Delivered</button>
                      </li>
                      <li class="radio active" v-if="order.order_line_status === 'Fail to Delivered'">
                        <button type="button" class="btn btn-outline-primary" @click="changeTrackOrder('Return to store')">Return to store</button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>

          </div>
          
          <div class="bottom-form">
						<div class="row">
							<div class="col-sm-4">
              </div>
              <div class="col-sm-2">
                <label>Items</label>
                <h4>{{ order.qty }}</h4>
              </div>
              <div class="col-sm-2">
                <label>Sub Total</label>
                <h4>Rs. {{ order.unit_price }}</h4>
              </div>
              <div class="col-sm-2">
                <label>Shipping and Handling fee</label>
                <h4>Rs. {{ order.delivery_charge}}</h4>
              </div>
              <div class="col-sm-2">
                <label>Total</label>
                <h4>Rs. {{ order.total_charge}}</h4>
              </div>
            </div>
					</div>

          </div>

        </div>
      </div>
  </div>  
</template>
<script>
import mixinOrder from "@/mixins/deliver/APIOrder"
export default {
  mixins: [mixinOrder],
  components: {
    // TrackOrder
  },
  data: function () {
    return {
      paymentType: 3,
      breadcrumb: [
        {
          text: "Order List",
          href: "/deliver-order-list",
          active: false
        },
        {
          text: "Order Configure",
          href: "#",
          active: true
        }
      ],
      order: {}
    }
  },
  computed: {
    oid() {
      let url = window.location.href.split("deliver-order-details/");
      return url[1];
    }
  },
  created(){
    this.handleBreadcrumb();
    this.getOrderDetails()
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getOrderDetails: async function() {
      try {
        let responce = await this.getOrderById(this.oid);
        this.order = responce;
        if (this.order.order_line_status === 0) {
          this.order.order_line_status = "Cancelled";
        } else if (this.order.order_line_status === 1) {
          this.order.order_line_status = "Pending";
        } else if (this.order.order_line_status === 10) {
          this.order.order_line_status = "Processing";
        } else if (this.order.order_line_status === 20) {
          this.order.order_line_status = "Ready to Pickup";
        } else if (this.order.order_line_status === 30) {
          this.order.order_line_status = "Shipped";
        } else if (this.order.order_line_status === 40) {
          this.order.order_line_status = "Delivered";
        } else if (this.order.order_line_status === 50) {
          this.order.order_line_status = "Fail to Delivered";
        } else if (this.order.order_line_status === 60) {
          this.order.order_line_status = "Return to store";
        } else if (this.order.order_line_status === 70) {
          this.order.order_line_status = "Accept Return";
        } else if (this.order.order_line_status === 41) {
          this.order.order_line_status = "Delivery Confirmed";
        }
        this.paymentType = this.order.payment_type;
      } catch (error) {
        console.log(error); 
      }
    },
    changeTrackOrder: function(status) {
      
      let self = this;

      if (status === "Delivered") {

        this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change Status!'
        }).then((result) => {
          if (result.value) {
            self.changeStatusToDeliverd();
          }
        })
      } else if (status === "Fail to Delivered") {

        this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change Status!'
        }).then((result) => {
          if (result.value) {
            self.changeStatusToFailToDeliverd();
          }
        })

      } else if (status === "Return to store") {

        this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change Status!'
        }).then((result) => {
          if (result.value) {
            self.changeStatusToReturnToStore();
          }
        })

      }
    },
    changeStatusToDeliverd: async function () {
       try {
        let object = {};
        object._id = this.order._id;
        let responce = await this.putChangeStatusDeliverdById(object);
        this.$swal.fire({
          position: 'top-right',
          type: 'success',
          title: "Order status change to Delivered!",
          showConfirmButton: false,
          timer: 1500
        })
        this.getOrderDetails();
      } catch (error) {
        console.log(error); 
      }
    },
    changeStatusToFailToDeliverd: async function () {
      try {
        let object = {};
        object._id = this.order._id;
        let responce = await this.putChangeStatusFailToDeliverdById(object);
        this.$swal.fire({
          position: 'top-right',
          type: 'success',
          title: "Order status change to Fail to Delivered!",
          showConfirmButton: false,
          timer: 1500
        })
        this.getOrderDetails();
      } catch (error) {
        console.log(error); 
      }
    },
    changeStatusToReturnToStore: async function () {
      try {
        let object = {};
        object._id = this.order._id;
        let responce = await this.putChangeStatusReturnToStoreById(object);
        this.$swal.fire({
          position: 'top-right',
          type: 'success',
          title: "Order status change to Return to store!",
          showConfirmButton: false,
          timer: 1500
        })
        this.getOrderDetails();
      } catch (error) {
        console.log(error); 
      }
    }
  }
}
</script>
<style scoped>
.radiocontainer input:checked ~ .checkbackground:after {
display: block;
}

.radiocontainer .checkbackground:after {
top: 0px;
left: 0px;
width: 100%;
height: 100%;
border-radius: 5%;
background:#0096aa75;
border:1px solid #000000;
}


.checkbackground {
position:relative;
border-radius: 5%;
/* border:1px solid #666666; */
background-color: #eee;
padding: 8px 10px;
margin-right:10px;
}

.checkbackground:after {
content: "";
position: absolute;
display: none;
}
.content-inner-order-details{
  min-height: 549px !important;
}
.delord-print-btn{
  background: #ffd338 !important;
  color: #000000 !important;
  border: none !important;
}
.delord-print-btn:hover{
  color: #ffffff !important;
}
</style>

