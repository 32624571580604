<template>
  <div class="well classWell" style="margin-top: -19px; padding-top: 8px; margin-bottom: 0px; border: none; background-color: white; webkit-box-shadow: none; *box-shadow: none;">
    <div class="row">
      <div class="col-md-6">
        <h3>Delivery Order Summary</h3>
        <DeliveryOrderSummary />
      </div>
      <div class="col-md-6">
        <div class="row">
          <h3>Cash On Delivery Payment Summary</h3>
          <CashOnDeliveryPaymentSummary />
        </div>
        <div class="row">
          <h3 style="margin-top: 10px;">Other Payment Summary</h3>
          <OtherPaymentSummary />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import DeliveryOrderSummary from "@/views/Deliver/DashBoardComponents/deliveryOrderSummary.vue";
  import CashOnDeliveryPaymentSummary from "@/views/Deliver/DashBoardComponents/CashOnDeliveryPaymentSummary.vue";
  import OtherPaymentSummary from "@/views/Deliver/DashBoardComponents/OtherPaymentSummary.vue";
  export default {
    components: {
      DeliveryOrderSummary,
      CashOnDeliveryPaymentSummary,
      OtherPaymentSummary
    },
    data() {
      return {}
    }
  }
</script>
<style>
#container {
	height: 300px;
	min-width: 310px;
	max-width: 800px;
    margin: 0 auto;
}

#button-bar {
    min-width: 310px;
    max-width: 800px;
    margin: 0 auto;
}

.panel { 
  box-shadow: none !important;
}

</style>