import Domain from "../domain";
export default {
  methods: {
    forgetPasswordSetFunctionDeliver: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "deliver/auth/forgot/password/set",
          obj,
          Domain.APP_BUYER_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    deliverRegister: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "deliver/auth/register",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    deliverMobileVerify: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "deliver/auth/verify/mobile",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    deliverMobileVerifyResend: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "deliver/auth/otp/resend/"+id,
          Domain.APP_DELIVER_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    deliverLogin: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "deliver/auth/login",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    firebaseLogin: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "common/oauth/login",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    }
  }
};
