import Domain from "../domain";
export default {
  methods: {
    getOrderList: async function(params) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "deliver/order/details?" + params,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOrderListGroup: async function(params) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "/deliver/order/new/details?" + params,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOrderLinesByOrderId: async function(orderId) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "/deliver/order/new/details/" + orderId,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getProvinceList: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN +
            "common/country/region/details/5d932c7a570adc5007bdb5dc",
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getCityList: async function(val) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "common/country/city/details/" + val,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    printOrderDetails: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN +
            "deliver/order/group/details/invoice/" +
            id,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    postPrintOrderDetails: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "deliver/order/bill/print", obj,
          Domain.APP_DELIVER_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error.response;
      }
    },
    getOrderListCount: async function(params) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "deliver/order/details/count?" + params,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getOrderById: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "deliver/order/details/" + id,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putChangeStatusDeliverdById: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "deliver/order/status/delivered",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putChangeStatusFailToDeliverdById: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "deliver/order/status/delivery/faild",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putChangeStatusReturnToStoreById: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "deliver/order/status/return",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getReturnOrder: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "deliver/order/dispute/details",
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getReturnOrderById: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "deliver/order/dispute/details/?order_no=" + id,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putChangeStatusDeliverPickupById: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "deliver/order/status/dispute/pickup",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getDeliveryDispatchList: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "deliver/order/stats/by/city?user=deliver",
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getCityDeliveryDispatchList: async function(status, cityId) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN +
            "deliver/order/stats/by/city/list?user=deliver&type=" +
            status +
            "&city=" +
            cityId,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    chgShippedToDelivered: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "deliver/order/status/delivered/bulk",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    chgShippedToDeliveredFail: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "deliver/order/status/delivery/faild/bulk",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  },
};
