<template>
<div class="classWell deliverZones" style=""> 
  <div class="panel-body row">
    <div class="col-sm-9" style="padding-left:0px;">
      <div class="input-group col-sm-3" style="padding-left:15px;">
        <input type="text" v-model="searchKey" class="form-control" id="input-store" placeholder="Search Zones" value="" name="voucher">          
      </div>
    </div>
    <div class="col-sm-3" style="padding-right:15px;">
      <!-- <button type="button" class="btn delprof-sbtn" style="float: right;" @click="handleCreate">
        Add New
      </button> -->
    </div>
  </div>  
  <h2 class="title">Delivery Zones</h2>
  <div class="row tbl-container">
    <table id="example" class="table table-striped table-bordered" >
      <thead>
        <tr>
          <th scope="col">Zone</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="column in tableItems" :key="column._id">
          <td class="text-center"><h4>{{ column.name }}</h4></td>
          <td class="product-action-style text-center">
            <button type="button" @click="haddleDownlord()" class="btn btn-sm btn-primary">Download City List</button>&nbsp;
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row text-right">
    <div class="col-sm-12">
        <div class="mt-3">
      <b-pagination 
      v-model="currentPage" 
      :per-page="pageSize"
      :total-rows="rowcount" 
      align="right"></b-pagination>
    </div>  

    </div>
    
  </div>
  

</div> 
</template>
<script>
import APIZones from "@/mixins/deliver/APIZones";
import Domain from "@/mixins/domain";
export default {
  components: { 
  },
  mixins: [APIZones,Domain],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Delivery Zones",
          href: "#",
          active: true
        }
      ],
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      storeListData: [
        {
          _id: "A",
          name: "A"
        },
        {
          _id: "B",
          name: "B"
        },
        {
          _id: "C",
          name: "C"
        },
        {
          _id: "D",
          name: "D"
        },
        {
          _id: "E",
          name: "E"
        }
      ],
    }
  },
  watch: {
    searchKey: function(val) {
      this.searchZones(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchZones(this.searchKey);
    }
  },
  created(){
    this.handleBreadcrumb();
    this.searchZones();
    // this.handleGetRatesList();
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    handleGetRatesList: async function() {
      try {
          // let response = await this.getDeliveryRates(); 
          // this.storeListData = response;
          // console.log("this.storeListData",this.storeListData)
          // for (let index = 0; index < this.storeListData.length; index++) {
          // }

          this.searchZones(""); 
              
      } catch (error) {   

      }
    },
    searchZones: function(keyword) {
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.tableItems = this.storeListData.slice(0, pagesize);
        this.rowcount = this.storeListData.length;
      } else {
        this.tableItems = this.storeListData
          .filter(obj => {
            {
              return (
                (obj.name &&
                  obj.name.toLowerCase().includes(keyword.toLowerCase()))
              );
            }
          })
          .slice(0, pagesize);

          this.rowcount = this.storeListData.length;
      }
      
    },   
    pagination: function(pagesize, currentPage) {
      
      let pagesizeint = parseInt(this.pageSize);

      let endsize = pagesize * currentPage;
      let startsize = 0;

      if(currentPage === 1){
        startsize = 0;
      } else {
        startsize = endsize - (pagesize) ;
      }     

      this.tableItems = this.storeListData.slice(startsize, endsize);

    }, 
    haddleDownlord: async function() {
      try {
          let res = await this.getDownlordCitySheet();
          let domainUrl = Domain.APP_DOMAIN.split("api/v1/")[0];
          let newUrl = domainUrl + res.url;
          window.open(newUrl);
          domainUrl = "";
      } catch (error) {
          this.$swal.fire({
              position: 'center',
              type: 'error',
              title: error.message,
              showConfirmButton: false,
              timer: 1500
          }); 
      }
    }
  }, 
}

</script>
<style>
#container {
	height: 300px;
	min-width: 310px;
	max-width: 800px;
    margin: 0 auto;
}
.tbl-container{
  padding-left: 15px;
  padding-right: 15px;
}
#button-bar {
    min-width: 310px;
    max-width: 800px;
    margin: 0 auto;
}
.deliverZones .delprof-sbtn{
    background: #5779ae !important;
    color: #ffffff;
    border: none;
}
.deliverZones .delprof-sbtn:hover{
    color: #000000 !important;
}
@media screen and (max-width: 1290px){
  .tbl-container{
    width: 100%;
    overflow-x: scroll;
  }
}
</style>