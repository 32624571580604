<template>
<div>
  <p class="lead">Hello, <strong>{{ basicInfo.first_name }} {{ basicInfo.last_name }}</strong> - please upload your kyc information.</p>
  <div class="row">
    
    <div class="col-sm-12">
      <label for="input-payment-country" class="control-label">Type</label>
      <select class="form-control" id="input-payment-country" name="country_id" @change="changeType($event.target.value)" v-model="saveImgObject.kyc_type">
        <option value="-1"> --- Please Select --- </option>
        <option value="1">NIC Number</option>
        <option value="2">Passport Number</option>
        <option value="3">Driving License Number</option>
      </select>
    </div>

    <div class="col-sm-12">
      <div class="form-group">
          <label for="input-middlename" class="control-label">{{ typeName }}</label>
          <input type="text" class="form-control" :placeholder="typeName" v-model="saveImgObject.card_no" :disabled="isDisable">
      </div>
    </div>

    <div class="col-sm-12">
      <div class="form-group">
          <label for="input-middlename" class="control-label">{{ nameOnThe }}</label>
          <input type="text" class="form-control" :placeholder="nameOnThe" v-model="saveImgObject.name" :disabled="isDisable">
      </div>
    </div>


    <div class="col-sm-12">
      <hr/>
     <p class="lead">please upload your images</p>
      <div class="row">
        <div class="col-sm-6">
            <div class="col-md-6 text-center">
                {{ front }} 
                <h5><b v-if="basicInfo.kyc_lists[0]">{{ basicInfo.kyc_lists[0].image_one_approve_status }}</b></h5>
                <h5><b v-if="basicInfo.kyc_lists[0] && basicInfo.kyc_lists[0].image_one_reject_reason && basicInfo.kyc_lists[0].image_one_approve_status === 'rejected'">Reject Reason: - {{ basicInfo.kyc_lists[0].image_one_reject_reason }}</b></h5>
                <img style="width: 300px; height: auto;" slot="image" class="card-img-top" :src="contactImage1" alt="Card image cap">
                <br>
                <br>
                <input v-if="!basicInfo.kyc_lists[0] || basicInfo.kyc_lists[0].image_one_approve_status === 'rejected'" type="file" class="inputfile" @change="getFile1">
            </div>

        </div>
        <div class="col-sm-6" v-if="passportVisible">
            <div class="col-sm-6 text-center">
                {{ back }} 
                <h5><b v-if="basicInfo.kyc_lists[0]">{{ basicInfo.kyc_lists[0].image_two_approve_status }}</b></h5>
                <h5><b v-if="basicInfo.kyc_lists[0] && basicInfo.kyc_lists[0].image_two_reject_reason && basicInfo.kyc_lists[0].image_one_approve_status === 'rejected'">Reject Reason: - {{ basicInfo.kyc_lists[0].image_two_reject_reason }}</b></h5>
                <img style="width: 300px; height: auto;" slot="image" class="card-img-top" :src="contactImage2" alt="Card image cap">
                <br>
                <br>
                <input v-if="!basicInfo.kyc_lists[0] || basicInfo.kyc_lists[0].image_two_approve_status === 'rejected'" type="file" class="inputfile" @change="getFile2">
            </div>
        </div>

      </div>
    </div>

  </div>

   <div class="row">
      <div class="col-sm-12" style="margin-top: 30px;">
          <div class="form-group text-right"v-if="!basicInfo.kyc_lists[0] || basicInfo.kyc_lists[0].image_one_approve_status === 'rejected'" >
              <button class="btn btn-primary" @click="submitKyc">Submit</button>
          </div>
      </div>
    </div>

</div>  
</template>
<script>
import mixinProfile from "../../../mixins/merchant/APIProfile";
export default {
  mixins: [mixinProfile],
  data: function () {
    return {
        basicInfo: {},
        
        fileUploadFormData: new FormData(),
        image1: "",
        image2: "",

        img1: "",
        img2: "",

        typeName: "Please select Type",
        nameOnThe: "Please select Type",
        isDisable: true,
        passportVisible: true,
        front: "",
        back: "",
        saveImgObject: {
          kyc_type: "-1"
        }
    }
   },
   computed: {
    contactImage1: function() {
      if (this.image1) {
        return this.image1;
      } else {
        return "assets/image/catalog/about/cl-image-1.jpg";
      }
    },
    contactImage2: function() {
      if (this.image2) {
        return this.image2;
      } else {
        return "assets/image/catalog/about/cl-image-1.jpg";
      }
    }
   },
   created() {
    this.getPersanalInfo();
   },
   methods: {
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        this.basicInfo = response;
        if (response.kyc_lists[0]) {
          this.saveImgObject = response.kyc_lists[0];
          this.image1 = response.kyc_lists[0].image1;
          this.image2 = response.kyc_lists[0].image2;
          if (response.kyc_lists[0].kyc_type === 1) {
            this.front = "ID Front";
            this.back = "ID Back";
          } else if (response.kyc_lists[0].kyc_type === 2) {
            this.front = "Passport Front";
          } else if (response.kyc_lists[0].kyc_type === 3) {
            this.front = "Driving License Front";
            this.back = "Driving License Back";
          }
        }
      } catch (error) {
        console.log(error);
      }
    },
    changeType: function(value) {
      if (value === "1") {
        this.saveImgObject.kyc_type = 1;
        this.typeName = "ID Number";
        this.nameOnThe = "Name on the ID Number"
        this.front = "ID Front";
        this.back = "ID Back";
        this.isDisable = false;
        this.passportVisible = true;
      } else if (value === "2") {
        this.saveImgObject.kyc_type = 2;
        this.typeName = "Passport Number";
        this.nameOnThe = "Name on the Passport Number"
        this.front = "Passport Front";
        this.isDisable = false;
        this.passportVisible = false;
      } else if (value === "3") {
        this.saveImgObject.kyc_type = 3;
        this.front = "Driving License Front";
        this.back = "Driving License Back";
        this.typeName = "Driving License Number";
        this.nameOnThe = "Name on the License Number"
        this.isDisable = false;
        this.passportVisible = true;
      } else {
        this.isDisable = true;
        this.passportVisible = true;
      }
    },
    getFile1: async function(eventf) {
      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          self.image1 = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }
      this.image1 = tgt.files[0];
      this.img1 = eventf.target.files[0]
    },
    getFile2: async function(eventf) {
      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          self.image2 = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }
      this.image2 = tgt.files[0];
      this.img2 = eventf.target.files[0]
    },
    submitKyc: async function() {
     await this.fileUploadFormData.set("kyc_type", this.saveImgObject.kyc_type);
     await this.fileUploadFormData.set("card_no", this.saveImgObject.card_no);
     await this.fileUploadFormData.set("name", this.saveImgObject.name);
     await this.fileUploadFormData.append("image", this.img1);
     await this.fileUploadFormData.append("image1", this.img2);

     let formData = await this.fileUploadFormData;

     try {
      if (this.saveImgObject._id) {
        await this.putKycUpdate(formData);
      } else {
        await this.putKyc(formData);
      }
      this.$swal.fire({
        position: 'center',
        type: 'success',
        title: 'Submit success',
        showConfirmButton: false,
        timer: 1500
      })
      this.getPersanalInfo();
     } catch (error) {
      this.$toast.error("Failed to submit details", "Error", {
        position: "topCenter"
      });
     }
    }
  }
}
</script>
