<template>
    <div>
        <div class="col-md-12" style="text-align:center; padding-top: 55px;">
            <h3>Forgot Your Password?</h3>
            <img src="assets/image/forgetpwd.png" style="display: block;width: 30%;margin: 0 auto;">
            <p>We are here to help you. Select Recover Your option.</p>
            Mobile<input style="margin-left: 1%; margin-right: 10%;" type="checkbox" @change="validationCheckBox('Mobile')" v-model="checkMobile">
            Email<input  style="margin-left: 1%;" type="checkbox" @change="validationCheckBox('Email')" v-model="checkEmail">
            <p v-if="checkMobile === true">Enter your registered mobile number to send a one time password (OTP) to reset your password.</p>
            <p v-if="checkEmail === true">Enter your registered email to send a link to reset your password.</p>
        </div>
        <div id="signForm">
            <div class="form-group col-md-12" style="text-align: center;">
                <input :style="changeColor1" @input="validateText(email)" v-model="email" :disabled="disableInput" :type="checkMobile === true ? 'number' : 'email'" :placeholder="checkMobile === true ? 'Mobile' : 'Email'" style="width: 400px; display: inline;" class="form-control">
            </div>
            <div class="form-group col-md-12" style="text-align: center;" v-if="message !== ''">
                <h5 style="color:red;">{{message}}</h5>
            </div>
            <div class="col-md-12" style="text-align:right; margin: 10px;"><hr/>
                <button style="#000000; width: 150px; display:inline; margin-right: 5px;" @click="backForgot()"  class="btn del-partfpwd-btn">Back to Login</button>
                <button :disabled="!formIsValid" @click="submit" style="width: 150px; display:inline;" class="btn del-partfpwd-btn">{{ checkMobile === true ? 'Send OTP' : 'Send Link' }}</button>
                <!-- Send Link -->
            </div>
        </div> 
    </div>
</template>
<script>
import mixinAuth from "@/mixins/buyer/APIAuth";
import mixinCommon from "@/mixins/APICommon";
// import firebase from 'firebase';
export default {
    mixins: [mixinAuth, mixinCommon],
    props: { 
        resettype: {
			type: String
		},
	}, 
    data() {
        return {
            changeColor1: {
                border: "1px solid #ccc"
            },
            checkMobile: true,
            checkEmail: false,
            form: {
                username: "",
                password: ""
            },
            dissableVal: false,
            disableInput: false,
            email: '',
            message: ''
        }
    },
    computed: {
    formIsValid() {
      return (
        !this.dissableVal && this.email
      );
    }
  },
  methods: {
        validateText(val) {
            var mobile = /^\d{10}$/;
            var email = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            if (email.test(String(val))) {
                this.changeColor1.border = "1px solid green";
                this.dissableVal = false;
            } else {
                if (val.match(mobile)) {
                    this.changeColor1.border = "1px solid green";
                    this.dissableVal = false;
                } else {
                    this.changeColor1.border = "1px solid red";
                    this.dissableVal = true;
                }
            }
            if (val === "") {
                this.changeColor1.border = "1px solid #ccc";
                this.dissableVal = true;
            }
        },
        validationCheckBox(type) {
            if (type === "Mobile") {
                this.checkEmail = false;
            } else {
                this.checkMobile = false;
            }
        },
        backForgot() {
            if(this.resettype === 'buyer') {
                this.$parent.showForgotPass(false, 'buyer');
            }
            if(this.resettype === 'merchant') {
                this.$parent.closeModal();
            }
            if(this.resettype === 'deliver') {
                this.$parent.closeModal();
            }
        },
        submit: async function() {
            try {
                let obj = {
                    username: this.email
                }
                let response = ''
                if(this.resettype === 'buyer' ) {
                    if(this.checkEmail){
                        response = await this.postBuyerRestPassword(obj);                      
                    } else if(this.checkMobile){
                        // this.$parent.showPwResetModal(this.email,response);
                        response = await this.postBuyerRestPasswordGetOtp(obj);  
                        if(response){
                            this.$parent.showPwResetModal(this.email,response);
                        }                    
                    }
                }
                if(this.resettype === 'merchant') {
                    response = await this.postMerchantRestPassword(obj);  
                }
                if(this.resettype === 'deliver') {
                    response = await this.postDeliverRestPassword(obj);  
                }
                if(response === '') {
                    return;
                }
                if(response.success === 1) {
                    // this.message = 'Reset link has been sent to your email.'
                    if(this.checkMobile) {
                        this.message = 'Your temporary password has been sent to your mobile number.';
                    }
                    if(this.checkEmail) {
                        this.message = 'Your temporary password has been sent to your email.';
                    }
                    
                    this.disableInput = true;
                    let self = this;
                    if(this.resettype === 'buyer' ) {
                        //  self.$parent.showPwResetModal();
                    }else{
                        setTimeout(function() { 
                            self.$parent.closeModal();
                         }, 5000); 
                    }
                         
                }
            } catch (error) {
                this.message = error.data.error;    
            }
        },
    //     loginWithFirebase: async function(authProvider) {
    //         let self = this; 
    //         if (authProvider === 1) {
    //             var provider = new firebase.auth.GoogleAuthProvider();
    //         } else if (authProvider === 2) {
    //             var provider = new firebase.auth.FacebookAuthProvider();
    //         }
    //         firebase.auth().signInWithPopup(provider).then(function(result) { 
    //             firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
    //             self.loginWithFirebaseFun(authProvider,idToken);
    //             }).catch(function(error) { 
    //                 this.$swal.fire({
    //                     position: 'center',
    //                     type: 'error',
    //                     title: 'Error in login.',
    //                     showConfirmButton: false,
    //                     timer: 1500
    //                 })
    //              }); 
    //         }).catch(function(error) { 
    //             var errorCode = error.code;
    //             var errorMessage = error.message; 
    //             var email = error.email; 
    //             var credential = error.credential; 
    //         });
    //     },
    //     loginWithFirebaseFun: async function(authProvider,idToken){
    //         try {
    //             let loginobj = { 
    //             "login_type": 1,
    //             "login_method": authProvider,
    //             "token": idToken
    //             }
    //             let response = await this.firebaseLogin(loginobj);
    //             if (response.status === 200) {
    //             localStorage.setItem("buyer_token", response.data.token);
    //             localStorage.setItem("buyer_type", 'buyer');
    //             localStorage.setItem("login_buyer", response.data._id);
    //             localStorage.setItem("buyer_data", JSON.stringify(response.data));
    //             this.$store.dispatch("doLoginbuyer");
    //             this.$swal.fire({
    //                 position: 'top-right',
    //                 type: 'success',
    //                 title: "Logged In!",
    //                 showConfirmButton: false,
    //                 timer: 1500
    //             })
    //             this.$router.push({ name: "Buyer Home" });
    //             }
    //             if(response.data._id !== ""){
    //             this.$swal.fire({
    //                 position: 'center',
    //                 type: 'success',
    //                 title: "Logged In!",
    //                 showConfirmButton: false,
    //                 timer: 1500
    //             })
    //             }
    //         } catch (error) {
    //             this.$swal.fire({
    //             position: 'center',
    //             type: 'error',
    //             title: error.data.message,
    //             showConfirmButton: false,
    //             timer: 1500
    //             })      
    //         }
    //     }
    }
}
</script>
<style>
    #modalsocialrow {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: baseline;
        padding: 25px 0px;
    }
    #signForm {
        border-bottom: solid 1px #f2f2f2;
        padding-bottom: 43px;
    }
    #signForm label{
        font-size: 14px;
        color: black;
    }
    .del-partfpwd-btn{
        background: #ffd338;
        color: #000000;
        border: none;
    }
    .del-partfpwd-btn:hover{
        color: #ffffff;
    }
    @media screen and (max-width: 425px) {
        #modalsocialrow img{
            width: 50%;
        }
    }
</style>
