<template>
<div class="classWell" style=""> 
  <div class="panel-body row">
    <div class="col-sm-9" style="padding-left:0px;">
      <div class="input-group col-sm-3" style="padding-left:15px;">
        <input type="text" v-model="searchKey" class="form-control" id="input-store" placeholder="Search Name" value="" name="voucher">          
      </div>
    </div>
    <div class="col-sm-3" style="padding-right:15px;">
      <button type="button" class="btn btn-primary" style="float: right;" @click="handleCreate">
        Add New
      </button>
    </div>
  </div>  
  <h2 class="title">Pickup Adderess List</h2>
  <div class="row tbl-container">
    <table id="example" class="table table-striped table-bordered" >
      <thead>
        <tr>
          <th scope="col">Name</th>
          <th scope="col">Address Line 1</th>
          <th scope="col">Address Line 2</th>
          <!-- <th scope="col">Postal Code</th> -->
          <th scope="col">Active Status</th>
          <th scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <div class="col-md-12" id="preview-area" v-if="blockLoading">
          <div class="row text-center">
            <div class="sk-chase">
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
              <div class="sk-chase-dot"></div>
            </div>
          </div>
        </div>
        <tr>
          <td colspan="7" class="text-center col-md-12" v-if="blockLoading === false && tableItems.length === 0">
            No Records Found!
          </td>
        </tr>
        <tr v-for="column in tableItems" :key="column._id">
          <td class="text-center">{{ column.name }}</td>
          <td class="text-center">{{ column.address_line_1 }}</td>
          <td class="text-center">{{ column.address_line_2 }}</td>
          <!-- <td class="text-center">{{ column.postal_code }}</td> -->
          <td class="text-center">{{ column.active_status === 1 ? "Active" : "Deactive" }}</td>
          <td class="product-action-style text-center">
            <button type="button" @click="pushEdit(column._id)" class="btn btn-sm btn-primary">Edit</button>&nbsp;
            <button v-if="tableItems.length > 1" type="button" @click="pushDelete(column._id)" class="btn btn-sm btn-danger">Delete</button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row text-right">
    <div class="col-sm-12">
        <div class="mt-3">
        <b-pagination 
          v-model="currentPage" 
          :per-page="pageSize"
          :total-rows="rowcount" 
          align="right">
        </b-pagination>
    </div>  

    </div>
    
  </div>
  

</div> 
</template>
<script> 
import APIPickup from "@/mixins/deliver/APIPickup";
import { async } from 'q';
export default {
  components: {},
  mixins: [APIPickup],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Pickup Address List",
          href: "#",
          active: true
        }
      ],
      blockLoading: true,
      pageSize: 10,
      currentPage: 1,
      rowcount: 0,
      searchKey: "",
      tableItems: [],
      storeListData: []
    }
  },
  watch: {
    searchKey: function(val) {
      this.searchAddress(val);
    },
    currentPage: function(val) {
      if (val) {
        this.pagination(this.pageSize, val);
      }
    },
    pageSize: function() {
      this.searchAddress(this.searchKey);
    }
  },
  created(){
    this.handleBreadcrumb();
    this.handleGetPickupList();
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    handleGetPickupList: async function() {
      try {
        this.blockLoading = true;
        this.storeListData = await this.getAllPickupAddress();
        this.searchAddress("");
        this.blockLoading = false;
      } catch (error) {
        this.blockLoading = true;
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: "Could not get data!",
          showConfirmButton: false,
          timer: 1500
        })
      }
    },
    searchAddress: function(keyword) {
      this.blockLoading = true;
      let pagesize = parseInt(this.pageSize);
      if (!keyword || keyword.length === 0) {
        this.tableItems = this.storeListData.slice(0, pagesize);
        this.rowcount = this.storeListData.length;
      } else {
        this.tableItems = this.storeListData
          .filter(obj => {
            {
              return (
                (obj.name &&
                  obj.name.toLowerCase().includes(keyword.toLowerCase())) ||
                (obj.address_line_1 &&
                  obj.address_line_1.toLowerCase().includes(keyword.toLowerCase())) ||
                (obj.address_line_2 &&
                  obj.address_line_2.toLowerCase().includes(keyword.toLowerCase())) ||
                (obj.postal_code &&
                  obj.postal_code.toLowerCase().includes(keyword.toLowerCase()))
              );
            }
          })
          .slice(0, pagesize);
        this.rowcount = this.storeListData.length;
      }
      this.blockLoading = false;
    },   
    pagination: function(pagesize, currentPage) {
      let pagesizeint = parseInt(this.pageSize);
      let endsize = pagesize * currentPage;
      let startsize = 0;
      if(currentPage === 1){
        startsize = 0;
      } else {
        startsize = endsize - (pagesize) ;
      }
      this.tableItems = this.storeListData.slice(startsize, endsize);

    }, 
    pushEdit(id) {
      this.$router.push({ name: "Pickup Address Create", params: { id: id } });
    },
    handleCreate: function() {
      this.$router.push({ name: "Pickup Address Create", params: { id: 0 } });
    },
    pushDelete: async function(id) {
      let self = this;
      this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          type: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
          if (result.value) {
            self.DeleteRate(id);
          }
        })
    },
    DeleteRate: async function(id) {
      try {
          let response = await this.deletePickupAddress(id);
          if(response.success){
            if(response.success === 1){
              this.$swal.fire({
                    position: 'center',
                    type: 'success',
                    title: response.message,
                    showConfirmButton: false,
                    timer: 1500
                  })
            } else {
              this.$swal.fire({
                position: 'center',
                type: 'error',
                title: response.message,
                showConfirmButton: false,
                timer: 1500
              })
            }
          }
          this.handleGetPickupList();
          this.searchAddress("");
      } catch (error) {   
        throw error; 
      }
    }
  },
}

</script>
<style>
#container {
	height: 300px;
	min-width: 310px;
	max-width: 800px;
    margin: 0 auto;
}
.tbl-container{
  padding-left: 15px;
  padding-right: 15px;
}
#button-bar {
    min-width: 310px;
    max-width: 800px;
    margin: 0 auto;
}
@media screen and (max-width: 1290px){
  .tbl-container{
    width: 100%;
    overflow-x: scroll;
  }
}
</style>