import Domain from "../domain";
export default {
  methods: {
    getProfileBacicInfo: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "deliver/profile/details",
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putProfileBacicInfo: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "deliver/profile", obj,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response;
      }
    },
    putProfileImage: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "deliver/profile/image", obj,
          Domain.APP_DELIVER_HEADER_FORM_DATA
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    putProfileAddress: async function(obj) {
      try {
        let response = await this.axios.put(
          Domain.APP_DOMAIN + "deliver/profile/addresses", obj,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    deleteProfileAddress: async function(id) {
      try {
        let response = await this.axios.delete(
          Domain.APP_DOMAIN + "deliver/profile/addresses/"+id,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    getDashboardStage: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "deliver/profile/dashboard/status",
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    postDashboardStage: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "deliver/profile/dashboard/status", obj,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  }
};
