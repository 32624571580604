<template>
	<div class="row">
			<div id="content" class="col-sm-12 deliverrateconfigure">
				<div class="page-login">
				
					<div >
						<div class="row">
              
								<div class="col-sm-12 customer-login">
									<h2 style="padding-left: 20px;">Rate Configure</h2>
									<div class="well content-inner">
                    <div class="zoneRowContainer">										
                      <div v-for="(zone, index)  in zonelist" :key="index" class="row zoneRow">
                        <div class="col-sm-1"></div>
                        <div class="col-md-2 col-sm-2">
                          <label class="control-label zoneLbl" for="input-password">Zone</label>
                          <input style="color: black;" type="text" class="form-control" disabled v-model="zone.zone_one" />
                        </div>
                        <div class="col-md-2 col-sm-2">
                          <label class="control-label zoneLbl" for="input-password">Standard Weight (kg)</label>
                          <input type="number" min="0" pattern="[0-9]" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" class="form-control" v-model="zone.standard_weight" />
                        </div>
                        <div class="col-md-2 col-sm-2">
                          <label class="control-label zoneLbl" for="input-password">Standard Rate (LKR)</label>
                          <input type="number" min="0" pattern="[0-9]" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" class="form-control" v-model="zone.standard_rate" />
                        </div>
                        <div class="col-md-2 col-sm-2">
                          <label class="control-label zoneLbl" for="input-password">Additional Weight (kg)</label>
                          <input type="number" min="0" pattern="[0-9]" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" class="form-control" v-model="zone.additional_kg_weight" />
                        </div>
                        <div class="col-md-2 col-sm-2">
                            <label class="control-label zoneLbl" for="input-password">Additional Rate (LKR)</label>
                            <input type="number" min="0" pattern="[0-9]" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');" class="form-control" v-model="zone.additional_kg_price" />
                        </div>
                      </div>
                    </div>
                    <hr/>
                    <h4 class="title">Delivery Agent Details</h4>

                    <div class="row">
                       <div class="col-sm-5">
                          <label class="control-label " for="input-password">Agent Name</label>
                          <input type="text" class="form-control" v-model="RateObj.name" />
                      </div>

                      <div id="mobileNumcol" class="col-sm-4">
                        <div class="form-group">
                          <label for="input-middlename" class="control-label"
                            >Mobile Number</label
                          >
                          <VuePhoneNumberInput
                            ref="mobilecom"
                            valid-color="green"
                            @input="validatePhone(RateObj.mobile)"
                            v-model="RateObj.mobile"
                            default-country-code="LK"
                            :translations="{ phoneNumberLabel: '' }"
                            :only-countries="['LK']"
                            no-example
                            style="height:30px; cursor:auto;"
                            id="mobileInput"
                          />
                        </div>
                      </div>
                      <div class="col-sm-3">
                        <label class="control-label " for="input-password">Status</label>
                        <select class="form-control" v-model="RateObj.active_status">
                              <option value="1">Active</option>
                              <option value="2">Deactive</option>
                        </select>
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="col-sm-3">
                        <label class="control-label " for="input-password">Status</label>
                        <select class="form-control" v-model="RateObj.active_status">
                              <option value="1">Active</option>
                              <option value="2">Deactive</option>
                        </select>
                      </div>
                    </div> -->
                    
									</div>
									<div class="bottom-form">
										<input type="button" value="Back" class="btn merchprof-sbtn  pull-left" @click.prevent.stop="BackToList" />
										<input type="button" value="Submit" :disabled="!formIsValid" class="btn merchprof-sbtn pull-right"  @click.prevent.stop="RateSubmit"/>
									</div>
								</div>
						</div>
					</div>
				</div>
			</div>
		</div>
</template>
<script>
import mixinCommon from "@/mixins/APICommon";
import mixinRates from "@/mixins/deliver/APIRates"; 
import mixinProfile from "@/mixins/deliver/APIProfile";
import VuePhoneNumberInput from "vue-phone-number-input";
export default {
  mixins: [mixinCommon, mixinRates, mixinProfile],
  components: {
    VuePhoneNumberInput,
  },
  data: function () {
    return {
      hmobileValid: false,
      disableCon: true,
      clickValue: 1,
      isVisible: false,
      provincelist: [],
      zonelist: [
        {
          zone_one: "A",
          standard_weight: 0,
          standard_rate: 0,
          additional_kg_weight: 0,
          additional_kg_price: 0
        },
        {
          zone_one: "B",
          standard_weight: 0,
          standard_rate: 0,
          additional_kg_weight: 0,
          additional_kg_price: 0
        },
        {
          zone_one: "C",
          standard_weight: 0,
          standard_rate: 0,
          additional_kg_weight: 0,
          additional_kg_price: 0
        },
        {
          zone_one: "D",
          standard_weight: 0,
          standard_rate: 0,
          additional_kg_weight: 0,
          additional_kg_price: 0
        },
        {
          zone_one: "E",
          standard_weight: 0,
          standard_rate: 0,
          additional_kg_weight: 0,
          additional_kg_price: 0
        }
      ],
      RateObj: {
        active_status: 1,
        name: "",
        mobile: ""
      },
      finalRate: {},
      packagesizes: [],
      breadcrumb: [
        {
          text: "Delivery Rates",
          href: "/deliver-rates",
          active: false
        },
        {
          text: "Delivery Rate Configure",
          href: "#",
          active: true
        }
      ],
      pickup: true
    }
  },
  computed: {
    formIsValid() {
      return (
        this.hmobileValid &&
        this.RateObj.name
      );
    },
    rid() {
      let url = window.location.href.split("deliver-rate-configure/");
      return url[1];
    }
  },
  created(){
    this.handleBreadcrumb();
    this.getRateByIdCheck();
    this.handleProvince();
    this.checkRequiredStage();
  },
  mounted() {
    //  vertically align center the test , flag , +94

    this.$refs.mobilecom.$el.children[0].children[0].children[0].children[0].style.marginTop =
      "0px";
    this.$refs.mobilecom.$el.children[0].children[0].children[0].style.marginTop =
      "-4px";
    this.$refs.mobilecom.$el.children[0].children[0].children[1].style.paddingTop =
      "3px";
    this.$refs.mobilecom.$el.children[1].children[0].children[0].style.paddingTop =
      "3px";

    const mobileInputTp = document.querySelector(".input-tel__input");
    mobileInputTp.setAttribute("maxlength", 9);
  },
  methods: {
    validatePhone(key) {
      if (key.length >= 10) {
        this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
          "1px solid green";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
          "green 0px 0px 0px 0px";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
          "green 0px 0px 0px 0px";
      }
      if (key.length < 10) {
        this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
          "1px solid red";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
          "red 0px 0px 0px 0px";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
          "red 0px 0px 0px 0px";
      }

      if (!this.RateObj.hasOwnProperty("mobile")) {
        this.hmobileValid = false;
        this.hmobileError = "Enter valid phone number";
        return;
      }

      var str = key;
      var res = str.substr(0, 9);
      this.RateObj.mobile = res;
      key = res;

      if (key === "0") {
        this.RateObj.mobile = "";
      }
      key = key.replace(/^0+/, "");
      key = key.replace(/ /g, "");
      this.RateObj.mobile = key;

      if (key.length == 9) {
        if (key == this.RateObj.mobile.substring(3)) {
          this.hmobileValid = false;
          this.hmobileError = "Existing Phone Number Detected";
          this.$refs.mobilecom.$children[1].$el.firstChild.style.borderColor =
            "red";
        } else {
          this.hmobileValid = true;
        }
      } else if (key.length === 10 || key.length < 10) {
        this.hmobileValid = false;
        this.hmobileError = "Enter valid phone number";
      }
      if (key.length < 9) {
        this.disableCon = true;
      } else {
        this.disableCon = false;
      }
    },
    getRates: async function() {
        let response = await this.getProfileBacicInfo();
        if(response.pickup){
            this.pickup = true
        } else {
            this.pickup = false
        }
        // this.$parent.checkRequiredStage();
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },    
    handleProvince: async function() {
      try {
          let response = await this.getProvince("5d8b9e5dfb195816aaea61b3"); 
          this.provincelist = response; 
              
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }
    },
    checkRequiredStage: async function() {
        let response = await this.getDashboardStage();

        if(response !== null){
          
          if(response.complated_status < 2){
            this.$router.push('/deliver-profile');
          } 
        } else {
          this.$router.push('/deliver-profile'); 
        } 
    },
    postRequiredStage: async function(stage) {
        let obj = {
                "complated_status": stage
        }

        let response = await this.postDashboardStage(obj);
    },
    RateSubmit: async function() {
      // if(!this.RateObj.zone_one  || !this.RateObj.standard_rate){
      // if(!this.RateObj.zone_one || !this.RateObj.zone_two || !this.RateObj.standard_rate){
      //    this.$swal.fire({
      //     position: 'center',
      //     type: 'error',
      //     title: 'Please Fill All Required Fields',
      //     showConfirmButton: false,
      //     timer: 1500
      //   });  
      //   return;
      // }

      // if(this.RateObj.zone_one === "" ||  this.RateObj.standard_rate === ""){
      if(this.RateObj.name === ""){
         this.$swal.fire({
          position: 'center',
          type: 'error',
          title: 'Please Fill All Required Fields',
          showConfirmButton: false,
          timer: 1500
        });  
        return;
      }

      if(this.RateObj.mobile == null){
         this.$swal.fire({
          position: 'center',
          type: 'error',
          title: 'Please Fill All Required Fields',
          showConfirmButton: false,
          timer: 1500
        });  
        return;
      }

      // if(this.RateObj.deliver_product_size.length === 0){
      //    this.$swal.fire({
      //     position: 'center',
      //     type: 'error',
      //     title: 'Please Select A Package Size',
      //     showConfirmButton: false,
      //     timer: 1500
      //   });  
      //   return;
      // }
      // else {
        
        // }

      try {
        this.finalRate = {};
        if (this.RateObj._id) {
          this.finalRate.zones = this.zonelist;
          this.finalRate.zones[0].active_status = this.RateObj. active_status;
          this.finalRate.name = this.RateObj.name;
          this.finalRate.mobile = this.RateObj.mobile;
          await this.putDeliveryRates(this.finalRate);
        } else {
          this.finalRate.zones = this.zonelist;
          this.finalRate.name = this.RateObj.name;
          this.finalRate.mobile = this.RateObj.mobile;
          this.finalRate.active_status = this.RateObj.active_status;
          await this.postRequiredStage(3);
          this.$store.commit("updatedeliverystage", 3); 
          await this.postDeliveryRates(this.finalRate);
           
        }   
        this.$swal.fire({
          position: 'center',
          type: 'success',
          title: 'success',
          showConfirmButton: false,
          timer: 1500
        })  
        this.$router.push("/deliver-rates");        
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: error.message,
          showConfirmButton: false,
          timer: 1500
        })      
      }
    },
    BackToList(){
      this.$router.push("/deliver-rates");        
    },
    getRateByIdCheck: async function() {
      if (this.rid !== "0") {
        try {
          let response = await this.getDeliveryRateByID(this.rid);
          this.RateObj = response;
          this.zonelist = [];
          // let obj = {};
          // obj.zone_one = this.RateObj.zone_one;
          // obj.standard_weight = this.RateObj.standard_weight;
          // obj.standard_rate = this.RateObj.standard_rate;
          // obj.additional_kg_price = this.RateObj.additional_kg_price;
          // obj.additional_kg_weight = this.RateObj.additional_kg_weight;
          this.zonelist.push(this.RateObj);
          this.validatePhone(this.RateObj.mobile);
        } catch (error) {
          throw error; 
        }
      }
    }
  }
}
</script>
<style>
.zoneRow {
  border: 1px solid black;
  padding-bottom: 20px;
  padding-top: 10px;
  margin-bottom: 20px;
}

.zoneRowContainer {
  padding: 20px;
}

.deliverrateconfigure .merchprof-sbtn{
  background:#5779ae !important;
  color: #ffffff !important;
  border: none;
}
.deliverrateconfigure .merchprof-sbtn:hover{
  color: #000000 !important;
}

.imgAdd {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #6eaf5f;
  color: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 32px;
  margin-top: 0px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 15px;
}

.imgDel {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #d53f3f;
  color: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 32px;
  margin-top: 0px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 15px;
}

@media screen and (max-width: 1525px) {
  .zoneLbl {
    min-height: 47px;
  }
}
</style>
