<template>
  <div> 
    <div class="panel-body row">
      <div class="col-sm-9" style="padding-left:0px;">
        <div class="input-group col-sm-3" style="padding-left:0px;">
          <input type="text" class="form-control" id="input-store" placeholder="Search Return Order" value="" name="voucher">          
        </div>
      </div>
      <div class="col-sm-12" style="margin-left:-10px;">
      <h2 class="title">Return Orders</h2>
      </div>
    </div>
    <table class="table ">

      <tbody>

        <div class="col-md-12" id="preview-area" v-if="blockLoading">
            <div class="row text-center">
                <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                </div>
            </div>
        </div>

        <tr v-for="(itemp, index) in cart_products" :key="index">
            
          <td class="text-center col-md-1"><a><img width="70px" :src="itemp.simple_product_id.images[0].image" alt="Aspire Ultrabook Laptop" title="Aspire Ultrabook Laptop" class="img-thumbnail"></a></td>
          
          <td class="text-left col-md-4" >
            
            <div class="row">
              <div class="col-md-12" style="font-size:14px; margin-bottom:5px;">								
                <a><b>{{ itemp.simple_product_id.product_id.product_name }}</b></a>
              </div>
            </div>		
            <div class="row">
              <div class="col-md-12">								
                Order ID : {{ itemp.order_env_number }}
              </div>
            </div>
           <div class="row">
              <div class="col-md-12" v-for="(itemp1, index1) in itemp.simple_product_id.attributes" :key="index1" >								
                {{ itemp1.attribute_id.attribute_name }} : {{ itemp1.attribute_value_id.lable}}
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">								
                Date : {{ itemp.date }}
              </div>
            </div>

          </td>

          <td class="text-center col-md-2" >
            
            <div class="left-content-product">
              <div class="content-product-right">	
                
                  <div class="form-group box-info-product">
                    <div class="option quantity">
                      <div class="input-group quantity-control" unselectable="on" style="-webkit-user-select: none; margin-top: 16px;">
                        <label><B>{{ itemp.dispute_status }}</B></label>
                      </div>
                    </div>			 
                  </div>
                            
              </div>
            </div>

          </td>

          <td class="text-right"  style="vertical-align: middle;"><B>{{ itemp.order_line_id.qty }}</B> Items</td>
          <td class="text-right"  style="vertical-align: middle;"><B>Rs:</B> {{ itemp.order_line_id.total_charge }}</td>
          <td class="text-right"  style="vertical-align: middle;" v-if="itemp.order_line_id.delivery_type === 1" >Cash on Delivery</td>
          <td class="text-right"  style="vertical-align: middle;" v-if="itemp.order_line_id.delivery_type === 2" >Card Payment</td>
          
          <td class="text-right"  style="vertical-align: middle;">
            <button type="button" data-toggle="tooltip" title="" class="btn btn-primary" onclick="" data-original-title="View" @click="handdleChange(itemp)">View</button>
          </td>

        </tr>
      </tbody>
    </table>

  </div>
</template>
<script>
import mixinOrder from "@/mixins/deliver/APIOrder"
export default {
mixins: [mixinOrder],
data(){
  return {
	  breadcrumb: [
		{
			text: "Return Order List",
			active: true
		}
		],
		subTotal: 0,
		total: 0,
		
		inputNumValue: 1,

		no_of_cart_items:0,
		cart_products:[],
		cartSavedProductIds:[],
		
		idObj: {
			ids: []
		},

    selectedArray: [],
    blockLoading: true
  }
},
created() {
	this.handleBreadcrumb();
	this.setReturnOrder();
},
methods:{
	handleBreadcrumb: function() {
		this.$store.commit("setBreadcrumbSwitchState", {
			action: null,
			payload: this.breadcrumb
		});
	},
  setReturnOrder: async function() {
    try {

      let responce = await this.getReturnOrder();
      this.cart_products = responce.map(obj=> {
        let dats = obj.order_id.date.split("GMT");
        obj.date = dats[0];

        if (obj.dispute_status === 1) {
          obj.dispute_status = "pending"
        } else if (obj.dispute_status === 10) {
          obj.dispute_status = "merchanet approve"
        } else if (obj.dispute_status === 20) {
          obj.dispute_status = "merchant reject"
        } else if (obj.dispute_status === 30) {
          obj.dispute_status = "send to admin"
        } else if (obj.dispute_status === 40) {
          obj.dispute_status = "pickup"
        } else if (obj.dispute_status === 50) {
          obj.dispute_status = "retuen approve"
        } else if (obj.dispute_status === 60) {
          obj.dispute_status = "money pass"
        }
        return obj;
      });

      this.blockLoading = false;

    } catch (error) {
      console.log(error);
    }
  },
  handdleChange: function(item) {
    this.$router.push({ name: "Deliver Return Orders Configure", params: { id: item.order_env_number } });
  }
  }
}
</script>