<template>
  <div class="deliverprof">
    <p class="lead">
      Hello,
      <strong style="color: #5779ae;"
        >{{ basicInfo.first_name }} {{ basicInfo.last_name }}</strong
      >
      - Welcome.
    </p>
    <div class="row">
      <div id="diliverImg" class="col-lg-3 col-md-12 col-sm-12 text-center">
        <img
          slot="image"
          class="card-img-top"
          :src="contactImage"
          alt="Card image cap"
        />
        <br />
        <br />
        <!-- <input type="file" class="btn btn-default mt-2 pt-1" @change="getFile" >Update Image</input> -->
        <button class="btn btn-sm delprof-sbtn" @click="pickProfPicture()">
          Upload
        </button>
        <input
          type="file"
          class="inputfile"
          id="embedpollfileinput"
          @change="getFile"
          ref="upprofpicturedel"
          style="display: none;"
        />
      </div>
      <div class="col-lg-9 col-md-12 col-sm-12">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-sm-12">
            <div class="form-group">
              <label for="input-middlename" class="control-label"
                >Company Name</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Company Name"
                v-model="basicInfo.company_name"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="form-group">
              <label for="input-middlename" class="control-label"
                >First Name</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="First Name"
                v-model="basicInfo.first_name"
                disabled
              />
            </div>
          </div>
          <!-- <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="form-group">
              <label for="input-middlename" class="control-label"
                >Middle Name</label
              >
              <input
                type="text"
                class="form-control"
                placeholder="Middle Name"
                v-model="basicInfo.middle_name"
              />
            </div>
          </div> -->
          <div class="col-lg-4 col-md-4 col-sm-4">
            <div class="form-group">
              <label for="input-middlename" class="control-label"
                >Last Name</label
              >
              <input
                disabled
                type="text"
                class="form-control"
                placeholder="Last Name"
                v-model="basicInfo.last_name"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div id="mobileNumcol" class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group">
              <label for="input-middlename" class="control-label"
                >Mobile No</label
              >
              <VuePhoneNumberInput
                ref="mobilecom"
                valid-color="green"
                @input="validatePhone(basicInfo.mobile)"
                v-model="basicInfo.mobile"
                default-country-code="LK"
                :translations="{ phoneNumberLabel: '' }"
                :only-countries="['LK']"
                no-example
                style="height:30px; cursor:auto;"
                id="mobileInput"
              />
              <!-- <VuePhoneNumberInput  v-model="basicInfo.mobile" default-country-code="LK" no-example style="height:30px;"/> -->
              <!-- <input type="text" class="form-control" placeholder="Mobile No" v-model="basicInfo.mobile"> -->
            </div>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12">
            <div class="form-group">
              <label for="input-middlename" class="control-label">Email</label>
              <input
                type="text"
                disabled
                class="form-control"
                placeholder="Email"
                v-model="basicInfo.email"
              />
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12" style="margin-top: 30px;">
            <div class="form-group text-right">
              <button
                class="btn delprof-sbtn footerClass2"
                @click="submitProfile"
                :disabled="disableCon"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinProfile from "../../../mixins/deliver/APIProfile";
import VuePhoneNumberInput from "vue-phone-number-input";
export default {
  components: {
    VuePhoneNumberInput,
  },
  mixins: [mixinProfile],
  data: function() {
    return {
      disableCon: true,
      basicInfo: {},
      fileUploadFormData: new FormData(),
      image: "",
      hmobileValid: true,
    };
  },
  computed: {
    contactImage: function() {
      if (this.image) {
        return this.image;
      } else {
        return "assets/image/catalog/about/cl-image-1.jpg";
      }
    },
  },
  beforemounted() {
    //  vertically align center the test , flag , +94

    this.$refs.mobilecom.$el.children[0].children[0].children[0].children[0].style.marginTop =
      "0px";
    this.$refs.mobilecom.$el.children[0].children[0].children[0].style.marginTop =
      "-4px";
    this.$refs.mobilecom.$el.children[0].children[0].children[1].style.paddingTop =
      "3px";
    this.$refs.mobilecom.$el.children[1].children[0].children[0].style.paddingTop =
      "3px";

    const mobileInputTp = document.querySelector(".input-tel__input");
    mobileInputTp.setAttribute("maxlength", 9);
  },
  created() {
    this.getPersanalInfo();
  },
  methods: {
    validatePhone(key) {
      if (key.length >= 10) {
        this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
          "1px solid green";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
          "green 0px 0px 0px 0px";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
          "green 0px 0px 0px 0px";
      }
      if (key.length < 10) {
        this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
          "1px solid red";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
          "red 0px 0px 0px 0px";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
          "red 0px 0px 0px 0px";
      }

      if (!this.basicInfo.hasOwnProperty("mobile")) {
        this.hmobileValid = false;
        this.hmobileError = "Enter valid phone number";
        return;
      }

      var str = key;
      var res = str.substr(0, 9);
      this.basicInfo.mobile = res;
      key = res;

      if (key === "0") {
        this.basicInfo.mobile = "";
      }
      key = key.replace(/^0+/, "");
      key = key.replace(/ /g, "");
      this.basicInfo.mobile = key;

      if (key.length == 9) {
        if (key == this.basicInfo.mobile.substring(3)) {
          this.hmobileValid = false;
          this.hmobileError = "Existing Phone Number Detected";
          this.$refs.mobilecom.$children[1].$el.firstChild.style.borderColor =
            "red";
        } else {
          this.hmobileValid = true;
        }
      } else if (key.length === 10 || key.length < 10) {
        this.hmobileValid = false;
        this.hmobileError = "Enter valid phone number";
      }
      if (key.length < 9) {
        this.disableCon = true;
      } else {
        this.disableCon = false;
      }
    },
    getPersanalInfo: async function() {
      try {
        let response = await this.getProfileBacicInfo();
        this.$parent.checkRequiredStage();
        this.basicInfo = response;
        if (this.basicInfo.image) {
          this.image = this.basicInfo.image;
        }
      } catch (error) {
        console.log(error);
      }
    },
    submitProfile: async function() {
      try {
        let response = await this.putProfileBacicInfo(this.basicInfo);
        // this.basicInfo = response;
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Submit success",
          showConfirmButton: false,
          timer: 1500,
        });
        let fullName =
          this.basicInfo.first_name + " " + this.basicInfo.last_name;
        this.$store.commit("updateDeliveryName", fullName);
      } catch (error) {
        console.log(error);
      }
    },
    pickProfPicture: function() {
      this.$refs.upprofpicturedel.click();
    },
    getFile: async function(eventf) {
      //   this.isLoading = true;
      await this.fileUploadFormData.append("image", eventf.target.files[0]);

      var tgt = eventf.target || window.event.srcElement;
      let files = tgt.files;

      let self = this;
      if (FileReader && files && files.length) {
        var fr = new FileReader();
        fr.onload = function() {
          self.image = fr.result;
        };
        fr.readAsDataURL(files[0]);
      }

      try {
        let formData = await this.fileUploadFormData;
        let response = await self.putProfileImage(formData);
        this.$swal.fire({
          position: "center",
          type: "success",
          title: "Submit Success",
          showConfirmButton: false,
          timer: 1500,
        });
        // this.isLoading = false;
        this.fileUploadFormData = new FormData();
        eventf.target.value = "";
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Submit Error",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
  },
};
</script>
<style scoped>
.deliverprof .delprof-sbtn {
  background: #5779ae !important;
  color: #ffffff;
  border: none !important;
}
.deliverprof .delprof-sbtn:hover {
  color: #000000;
}
@media screen and (max-width: 1200px) {
  #diliverImg #embedpollfileinput {
    margin-left: auto;
    margin-right: auto;
  }
}</style
>>
