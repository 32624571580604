import Domain from "../domain";
export default {
  methods: {
    createPickupAddress: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "deliver/profile/pickup/address",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response;
      } catch (error) {
        throw error.response;
      }
    },
    updatePickupAddress: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "deliver/profile/pickup/address",
          obj,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response;
      }
    },
    getAllPickupAddress: async function() {
        try {
          let response = await this.axios.get(
            Domain.APP_DOMAIN + "deliver/profile/pickup/address/details/",
            Domain.APP_DELIVER_HEADER
          );
          return response.data;
        } catch (error) {
          throw error.response;
        }
    },
    getpickupAddressById: async function(id) {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "deliver/profile/pickup/address/details/" + id,
          Domain.APP_DELIVER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response;
      }
    },
    deletePickupAddress: async function(id) {
        try {
          let response = await this.axios.delete(
            Domain.APP_DOMAIN + "deliver/profile/pickup/address/" + id,
            Domain.APP_DELIVER_HEADER
          );
          return response.data;
        } catch (error) {
          throw error;
        }
      }
  }
};
