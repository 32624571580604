<template>
	<div class="row">
			<div id="content" class="col-sm-12">
				<div class="page-login">
				
					<div class="account-border">
          <div class="well" style="min-height: auto !important;">										
                
						<div class="row">
              
							<div class="col-sm-12 customer-login">
								<h2>Order Details</h2>
                <hr/>
              </div>

            </div>

            <div class="row">
              
							<div class="col-sm-4">
								<img width="100px" :src="order[0].simple_product_id.images[0].image" alt="Aspire Ultrabook Laptop" title="Aspire Ultrabook Laptop" class="img-thumbnail">             

                <ul class="product-options clearfix">
                  <li class="radio active">
                    <h4><b>{{ order[0].simple_product_id.product_id.product_name }} / {{ order[0].simple_product_id.attributes[0].attribute_value_id.lable }} / {{  order[0].simple_product_id.attributes[1].attribute_value_id.lable }}</b></h4>
                  </li>

					      	<p class="p-shipping-details"><img src="assets/image/sample_product_images/clock.svg" style="width:24px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;30 Days Buyer Protection</p>
			      			<!-- <p class="p-shipping-details"><img src="assets/image/sample_product_images/logistics-delivery-truck-in-movement.svg" style="width:28px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Dispute Order Status : {{ order[0].dispute_status}}</p> -->
					      	<p class="p-shipping-details"><img src="assets/image/sample_product_images/money.svg" style="width:25px;" />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Payment Method: Cash on Delivery</p>

                </ul>
                
              </div>
              
              <div class="col-sm-4">
                 <div class="row">
                  <div class="col-sm-12">
                    <h4>Buyer Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
                        <p>Name : {{ order[0].order_id.user_id.first_name}} {{ order[0].order_id.user_id.last_name}}</p>
                      </li>
                      <li class="radio active">
                        <p>Mobile : {{ order[0].order_id.user_id.mobile}}</p>                    
                      </li>
                      <li class="radio active">
                        <p>Email : {{ order[0].order_id.user_id.email}}</p>                                        
                      </li>
                    </ul>

                  </div>
                </div>  
                <hr/>
                <div class="row">
                  <div class="col-sm-12">
                    <h4>Merchant Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
                        <p>Name : {{ order[0].merchant_id.first_name}} {{ order[0].merchant_id.last_name}}</p>
                      </li>
                      <li class="radio active">
                        <p>Mobile : {{ order[0].merchant_id.mobile}}</p>                    
                      </li>
                      <li class="radio active">
                        <p>Email : {{ order[0].merchant_id.email}}</p>                                        
                      </li>
                    </ul>

                  </div>
                </div>  
              </div>

              <div class="col-sm-4">
                <div class="row">
                  <div class="col-sm-12">
                     <h4>Billing Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
                        <p>Order Number : {{ order[0].order_id.order_env_number}}</p>
                      </li>
                      <li class="radio active">
                        <p>Billing Name : {{ order[0].order_id.billing_name}}</p>
                      </li>
                      <li class="radio active">
                        <p>Mobile : {{ order[0].order_id.billing_mobile}}</p>                    
                      </li>
                      <li class="radio active">
                        <p>Address : {{ order[0].order_id.address_id.address}} {{ order[0].order_id.address_id.postal_code}}</p>                                        
                      </li>
                      <li class="radio active">
                        <p>Date : {{ order[0].order_id.date}}</p>                                        
                      </li>
                    </ul>
                  </div>
                </div>
                <hr/>
                 <div class="row">
                  <div class="col-sm-12">
                     <h4>Dispute Information</h4>

                    <ul class="product-options clearfix">
                      <li class="radio active">
					        			<img width="100px" :src="order[0].images[0]" alt="Aspire Ultrabook Laptop" title="Aspire Ultrabook Laptop" class="img-thumbnail">                                   
                      </li>
                      <li class="radio active">
                        <p>Discribe Reason : {{ order[0].discribe_reason}}</p>
                      </li>
                      <li class="radio active">
                        <p>Dispute Status : <b>{{ order[0].dispute_status}}</b></p>                    
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="row" v-if="order[0].dispute_status === 'merchanet approve'">
                  <div class="col-sm-12">
                    <h4>Action</h4>
                    <ul class="product-options clearfix">
                      <li class="radio active" v-if="order[0].dispute_status === 'merchanet approve'">
                        <button type="button" class="btn btn-outline-primary" @click="changeTrackOrder('pickup')">Pickup</button>&nbsp;&nbsp;&nbsp;
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

            </div>

          </div>
          
          <div class="bottom-form">
						<div class="row">
							<div class="col-sm-4">
              </div>
              <div class="col-sm-2">
                <label>Items</label>
                <h4>{{ order[0].order_line_id.qty }}</h4>
              </div>
              <div class="col-sm-2">
                <label>Sub Total</label>
                <h4>Rs. {{ order[0].order_line_id.unit_price }}</h4>
              </div>
              <div class="col-sm-2">
                <label>Shipping and Handling fee</label>
                <h4>Rs. {{ order[0].order_line_id.delivery_charge}}</h4>
              </div>
              <div class="col-sm-2">
                <label>Total</label>
                <h4>Rs. {{ order[0].order_line_id.total_charge}}</h4>
              </div>
            </div>
					</div>

          </div>

        </div>
      </div>
  </div>  
</template>
<script>
import mixinOrder from "@/mixins/deliver/APIOrder"
export default {
  mixins: [mixinOrder],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Return Order List",
          href: "/deliver-return-orders",
          active: false
        },
        {
          text: "Return Order View",
          href: "#",
          active: true
        }
      ],
      order: {}
    }
  },
  computed: {
    oid() {
      let url = window.location.href.split("deliver-return-orders-configure/");
      return url[1];
    }
  },
  created(){
    this.handleBreadcrumb();
    this.getOrderDetails()
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    getOrderDetails: async function() {
      try {
        let responce = await this.getReturnOrderById(this.oid);
        this.order = responce;

        if (this.order[0].dispute_status === 1) {
          this.order[0].dispute_status = "pending"
        } else if (this.order[0].dispute_status === 10) {
          this.order[0].dispute_status = "merchanet approve"
        } else if (this.order[0].dispute_status === 20) {
          this.order[0].dispute_status = "merchant reject"
        } else if (this.order[0].dispute_status === 30) {
          this.order[0].dispute_status = "send to admin"
        } else if (this.order[0].dispute_status === 40) {
          this.order[0].dispute_status = "admin approve"
        } else if (this.order[0].dispute_status === 50) {
          this.order[0].dispute_status = "pickup"
        } else if (this.order[0].dispute_status === 60) {
          this.order[0].dispute_status = "retuen approve"
        } else if (this.order[0].dispute_status === 70) {
          this.order[0].dispute_status = "money pass"
        }

      } catch (error) {
        console.log(error); 
      }
    },
    changeTrackOrder: function(status) {
      let self = this;
      if (status === "pickup") {
        this.$swal.fire({
          title: 'Are you sure?',
          text: "You won't be able to revert this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Yes, Change Status!'
        }).then((result) => {
          if (result.value) {
            self.changeStatusToDeliverPickup();
          }
        })
        // this.changeStatusToDeliverPickup();
      }
    },
    changeStatusToDeliverPickup: async function () {
       try {
        let object = {};
        object._id = this.order[0]._id;
        let responce = await this.putChangeStatusDeliverPickupById(object);
        this.$swal.fire({
          position: 'top-right',
          type: 'success',
          title: "Order status change to Pickup!",
          showConfirmButton: false,
          timer: 1500
        })
         this.$router.push({ name: "Deliver Return Orders" });
      } catch (error) {
        console.log(error); 
      }
    }
  }
}
</script>
<style>
.radiocontainer input:checked ~ .checkbackground:after {
display: block;
}

.radiocontainer .checkbackground:after {
top: 0px;
left: 0px;
width: 100%;
height: 100%;
border-radius: 5%;
background:#0096aa75;
border:1px solid #000000;
}


.checkbackground {
position:relative;
border-radius: 5%;
/* border:1px solid #666666; */
background-color: #eee;
padding: 8px 10px;
margin-right:10px;
}

.checkbackground:after {
content: "";
position: absolute;
display: none;
}
</style>

