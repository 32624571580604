<template>
  <div>
    <p class="lead">You can change your pickup address here.</p>
    <div class="row">
      <div class="col-sm-12">
        <div>
          <div class="row">
              <div class="col-sm-12">
                <div class="form-group required">
                  <label class="control-label" for="input-password">Name</label>
                  <input type="text" class="form-control" v-model="address.name"/>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group required">
                  <label>Country</label>
                  <select class="form-control select-arrow" @change="changeCountries($event.target.value, index, true)" v-model="address.country" >
                    <option dissabled value="1"> --- Please Select --- </option>
                    <option v-for="(item, val) in countries" :key="val" :value="item._id">{{ item.country_name}}</option>
                  </select>
                </div>
              </div>
                <div class="col-sm-6">
                  <div class="form-group required">
                  <label>Province</label>
                    <select class="form-control select-arrow" @change="changeProvince($event.target.value, index, true)" v-model="address.province" >
                      <option dissabled value="1"> --- Please Select --- </option>
                      <option v-for="(item, val) in provinceArray" :key="val" :value="item._id">{{ item.province_name}}</option>
                    </select>
                  </div>
                </div>
            </div>
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group required">
                <label>District / Area</label>
                  <select class="form-control select-arrow" @change="changeRegion($event.target.value, index, true)" v-model="address.region">
                    <option dissabled value="1"> --- Please Select --- </option>
                    <option  v-for="(item, val) in regionArray" :key="val" :value="item._id">{{ item.region_name}}</option>
                  </select>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group required">
                <label>City</label>
                  <select class="form-control select-arrow" v-model="address.city">
                    <option dissabled value="1"> --- Please Select --- </option>
                    <option  v-for="(item, val) in cityArray" :key="val" :value="item._id">{{ item.city_name}}</option>
                  </select>
                </div>
              </div>
            </div>
            <!-- <div class="row">
              <div class="col-sm-6">
                <label class="control-label" for="input-password">Post Code</label>
                <input type="text" class="form-control" v-model="address.postal_code" />
              </div>
            </div> -->
            <div class="row">
              <div class="col-sm-6">
                <div class="form-group required">
                  <label class="control-label" for="input-password">Address Line 1</label>
                  <input type="text" class="form-control" placeholder="Home Number" v-model="address.address_line_1"/>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="form-group required">
                  <label class="control-label" for="input-password">Address Line 2</label>
                  <input type="text" class="form-control" placeholder="Lane" v-model="address.address_line_2"/>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="form-group text-right">
                <button class="btn btn-primary" @click="submitProfileAddress" :disabled="!formIsValid">Submit</button>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import mixinCommon from "@/mixins/APICommon";
import mixinProfile from "@/mixins/deliver/APIProfile";
import APIPickup from "@/mixins/deliver/APIPickup";
export default {
  mixins: [mixinCommon, mixinProfile, APIPickup],
  data: function () {
    return {
      breadcrumb: [
        {
          text: "Pickup Address List",
          href: "/deliver-pickup-address",
          active: false
        },
        {
          text: "Pickup Address Create",
          href: "#",
          active: true
        }
      ],
      valIndex: 0,
      countries: [],
      province: [],
      region: [],
      city: [],
      provinceArray:[],
      regionArray:[],
      cityArray:[],
      address: {
        country: "",
        province: "1",
        region: "1",
        city: "1"
      },
      isCheck: true,
      multiAddress: true
    }
  },
  computed: {
    addressId() {
      let url = window.location.href.split("deliver-pickup-address-submit/");
      return url[1];
    },
    formIsValid() {
      return (
        this.address.country !== "1" &&
        this.address.province !== "1" &&
        this.address.region !== "1" &&
        this.address.city !== "1" &&
        this.address.name &&
        this.address.address_line_1 &&
        this.address.address_line_2
        // this.address.postal_code
      );
    }
  },
  created(){
    this.handleBreadcrumb();
    this.getCountryDetails();
    this.getAddresses();
  },
  methods: {
    getAddresses: async function() {
      try {
        if (this.addressId !== "0") {
          this.address = await this.getpickupAddressById(this.addressId);
          this.changeCountries(this.address.country);
          this.changeProvince(this.address.province);
          this.changeRegion(this.address.region);
        }
      } catch (error) {
        console.log(error);
        // this.$swal.fire({
        //   position: 'center',
        //   type: 'error',
        //   title: "Could not get details!",
        //   showConfirmButton: false,
        //   timer: 1500
        // })
      }
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },
    postRequiredStage: async function(stage) {
        let obj = {
                "complated_status": stage
        }

        let response = await this.postDashboardStage(obj);
    
    },
    getCountryDetails: async function() {
      try {
        let response = await this.getCountry(); 
        this.countries = response;
        this.address.country = this.countries[0]._id
        this.changeCountries(this.countries[0]._id, 0, true)
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        })
      }
    },
    changeCountries: async function(event, index, useraction = false) {
      try {
        let response = await this.getProvince(this.countries[0]._id);
        this.provinceArray = response;
        this.regionArray[index] = [];
        this.cityArray[index] = [];
        if(Object.keys(response).length === 0 || useraction === true){
          this.addressArray[index].province = "";
          this.addressArray[index].region = "";
          this.addressArray[index].city = "";
        }
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        })      
      }
    },
    changeProvince: async function(event, index, useraction = false) {
      try {
          let response = await this.getRegion(event); 
          this.regionArray = response;
          this.cityArray[index] = [];
          if(Object.keys(response).length === 0 || useraction === true){
            this.addressArray[index].region = "";
            this.addressArray[index].city = "";
          }
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        })
      }
    },
    changeRegion: async function(event, index, useraction = false) {
      try {
          let response = await this.getCity(event); 
          this.cityArray = response;
          if(Object.keys(response).length === 0 || useraction === true){
            this.addressArray[index].city = ""; 
          }  
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500
        })      
      }
    },
    submitProfileAddress: async function() {
      try {
        if (this.addressId === "0") {
          await this.postRequiredStage(4);
          await this.createPickupAddress(this.address);
        } else {
          await this.updatePickupAddress(this.address);
        }
        this.$swal.fire({
          position: 'center',
          type: 'success',
          title: "Submit success!",
          showConfirmButton: false,
          timer: 1500
        })
        this.$router.push({ name: "Pickup Address" });
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: "Failed to submit!",
          showConfirmButton: false,
          timer: 1500
        })
        throw error;
      }
    }
  }
}
</script>
