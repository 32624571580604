<template>
  <div>
    <div
      class="row"
      style="margin-top:20px;background: #ffffff;"
      id="delPartLgn"
    >
      <div id="content" class="col-md-12">
        <div class="page-login">
          <div class="account-border">
            <div class="row" style="display: flex; flex-wrap: wrap;">
              <div class="col-md-6 new-customer firstCardCustomer align-end">
                <div class="col-md-10" style="text-align: center;">
                  <h2>Welcome to your ‘Own Delivery’ customized delivery portal!</h2>
                  <p class style="margin-top:20px;">
                    ‘Own Delivery’ is selected by merchants who own or have access to island wide delivery
                    networks that ensure better customer satisfaction.
                    <!-- By creating an account you will be able to shop faster, be up to date on an order's status, and keep track of the orders you have previously made. -->
                  </p>
                  <div style="margin-top:20px;">
                    <img
                      src="https://res.cloudinary.com/sri-lanka-telecom-plc/image/upload/q_auto,f_auto/v1629964702/cochchi/mercahnt/1629964701106.webp"
                    />
                  </div>
                </div>
              </div>

                <div class="col-md-6 customer-login align-end">
                  <div class="rect-right">
                    <div style="padding-right: 20px; padding-left: 20px;">
                      <div>
                        <div class="content-right">
                          <img
                            class="supiripola_delivery_logo"
                            :src="this.$store.state.whiteLogo"
                            title="Your Store"
                            alt="Your Store"
                          />
                        </div>
                        <form @submit.prevent="checkForm">
                        <div class="form-group" style="margin-top:50px;">
                          <!-- <label class="control-label " for="input-email">E-Mail Address</label> -->
                          <input
                            placeholder="Username"
                            type="email"
                            required
                            v-model="form.username"
                            v-on:keyup.enter="onEnter()"
                            name="email"
                            value
                            id="input-email"
                            class="form-control"
                          />
                        </div>
                        <div
                          class="form-group input-password"
                          style="margin-top:40px;"
                        >
                          <!-- <label class="control-label " for="input-password">Password</label> -->
                          <input
                            type="password"
                            placeholder="Password"
                            required
                            v-model="form.password"
                            name="password"
                            value
                            id="input-password"
                            class="form-control"
                            ref="inputLgnPwdDp"
                            v-on:keyup.enter="onEnter()"
                          />
                          <span
                            class="toggleIcon"
                            @click="togglePwd()"
                            ref="toggleIcon"
                          >
                            <i class="fa fa-eye"></i>
                          </span>
                        </div>
                        <div class="row-submit-btn">
                          <input
                            id="btnlogin"
                            type="submit"
                            :disabled="disableBtn"
                            value="submit"
                            class="btn delprof-sbtn del-lgn-btn"
                          />
                        </div>
                      </form>
                      </div>
                      <div class>
                        <!-- <a data-toggle="modal" data-target="#DeliverLoginModal" class="forgot">Forgot Password?</a> -->
                        <!-- <a type="button" @click="viewForgetPass">
                          Forgot Password ?
                        </a>-->
                      </div>
                      <!-- <div style="text-align:center;margin-top:30px;">
                        Want become a delivery Partner?
                        <a href="/deliver-register" class="signup-link"
                          >Sign up here</a
                        >
                      </div> -->
                    </div>
                  </div>
                  <!-- <div class="bottom-form">
                  <span class="pull-right" style="font-size:30px;">
                    <button type="button" title="" class="btn btn-success btn-google btnGoogle" style="margin-right: 10px;" @click=loginWithFirebasePopup(1)>
                      <i class="fa fa-google login-google" style="font-size:20px; display: flex; flex: 1;"></i>
                      <span class="login-text">Login with Google</span>
                    </button>
                    <button type="button" title="" class="btn btn-facebook btnFB" @click=loginWithFirebasePopup(2)>
                      <i class="fa fa-facebook login-google" style="font-size:20px; display: flex; flex: 1;"></i>
                      <span class="login-text">Login with Facebook</span>
                    </button>
                  </span>
                  </div>-->
                </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <!-- <div v-show="hideModal === 1" class="modal fade" id="DeliverLoginModal" role="dialog">
    <div class="modal-dialog  modal-lg">
    
      <div class="modal-content">
        <div class="modal-body" style="padding: 0px;">
            <div class="row" id="buyerModalContent">
                <div class="col-md-12" style="display: inline-block;">
                    <div>
                        <button type="button" style="font-size: 35px; font-weight: 500;right: 30px; top: 15px; position: absolute; z-index:100;" data-dismiss="modal" class="close" @click="closeModal()">&times;</button>
                    </div>
                    <DeliverForgotPassword v-bind:resettype="resettype" />
                    
                </div> 
            </div>
        </div>
      </div>
      
    </div>
    </div>-->
  </div>
</template>
<script>
// Firebase Auth
import firebase from "firebase";

import mixinAuth from "@/mixins/deliver/APIAuth";
import DeliverForgotPassword from "@/components/common/modals/forgotPassword.vue";
export default {
  name: "deliverLogin",
  mixins: [mixinAuth],
  components: {
    DeliverForgotPassword,
  },
  data() {
    return {
      disableBtn: false,
      form: {
        username: "",
        password: "",
      },
      activetab: 1,
      hideModal: 1,
      showforgotpass: false,
      resettype: "deliver",
    };
  },
  watch: {
    $route(to, from) {
      this.menuUnLock();
    },
    $store() {
      this.menuUnLock();
    },
  },
  created() {
    this.menuUnLock();
    this.loggedPush();
  },
  methods: {
    viewForgetPass() {
      this.$router.push("/reset-password-1") ;
    },
    loggedPush() {
      if (this.$store.state.deliver_accessToken !== null) {
        this.$router.push("/deliver-order-group");
      }
    },
    closeModal() {
      $("#DeliverLoginModal").modal("hide");
      this.hideModal = 0;
      // location.reload();
    },
    menuUnLock() {
      if (document.body.classList.contains("common-home")) {
        document.querySelector("body").classList.remove("common-home");
      }
    },
    register() {
      this.$router.push({ name: "Delivery Partner register" });
    },
    handleContinue: function() {
      this.$router.push({ path: "/" });
    },
    onEnter() {
      document.getElementById("btnlogin").click();
    },
    checkForm: async function(e) {
      this.disableBtn = true;
      e.preventDefault();

      try {
        let response = await this.deliverLogin(this.form);
        if (response.status === 200) {
          localStorage.setItem("deliver_token", response.data.token);
          localStorage.setItem("deliver_type", "deliver");
          localStorage.setItem("login_deliver", response.data._id);
          localStorage.setItem("deliver_data", JSON.stringify(response.data));
          this.$store.dispatch("doLogindeliver");

          this.$swal.fire({
            position: "top-right",
            type: "success",
            title: "Logged In!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        if (response.data._id !== "") {
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Logged In!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
        this.disableBtn = false;
        this.$router.push("/deliver-order-group");
        // this.$router.push({path:"/deliver-dashbord"});
      } catch (error) {
        this.disableBtn = false;
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    // function firebase - login with firebase popup
    loginWithFirebasePopup: function(authProvider) {
      let self = this;
      if (authProvider == 1) {
        var provider = new firebase.auth.GoogleAuthProvider();
      } else if (authProvider == 2) {
        var provider = new firebase.auth.FacebookAuthProvider();
      }
      firebase
        .auth()
        .signInWithPopup(provider)
        .then(function(result) {
          firebase
            .auth()
            .currentUser.getIdToken(true)
            .then(function(idToken) {
              self.loginWithFirebase(authProvider, idToken);
            })
            .catch(function(error) {
              alert("error.");
            });
        })
        .catch(function(error) {
          var errorCode = error.code;
          var errorMessage = error.message;
          var email = error.email;
          var credential = error.credential;
        });
    },
    // login with farebase call mixin function
    loginWithFirebase: async function(authProvider, idToken) {
      try {
        let loginobj = {
          login_type: 3,
          login_method: authProvider,
          token: idToken,
        };
        let response = await this.firebaseLogin(loginobj);
        if (response.status === 200) {
          localStorage.setItem("deliver_token", response.data.token);
          localStorage.setItem("deliver_type", "deliver");
          localStorage.setItem("login_deliver", response.data._id);
          localStorage.setItem("deliver_data", JSON.stringify(response.data));
          this.$store.dispatch("doLogindeliver");
          this.handleContinue();
        }
        if (response.data._id !== "") {
          this.$swal.fire({
            position: "center",
            type: "success",
            title: "Logged In!",
            showConfirmButton: false,
            timer: 1500,
          });
        }
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: error.data.message,
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    togglePwd: function() {
      if (this.$refs.inputLgnPwdDp.type === "password") {
        this.$refs.inputLgnPwdDp.type = "text";
        this.$refs.toggleIcon.innerHTML = `<i class="fa fa-eye-slash"></i>`;
      } else {
        this.$refs.inputLgnPwdDp.type = "password";
        this.$refs.toggleIcon.innerHTML = `<i class="fa fa-eye"></i>`;
      }
    },
  },
};
</script>
<style>
#delPartLgn .input-password input[type="password"] {
  position: relative;
}
#delPartLgn .input-password span.toggleIcon {
  position: relative;
  display: block;
  float: right;
  top: -31px;
  right: 7px;
  cursor: pointer;
}
#delPartLgn .input-password .fa {
  font-size: 15px;
}
.delprof-sbtn {
  /* width: 100%; */
  background: #000000 !important;
  color: #ffffff !important;
  border: none !important;
}
.delprof-sbtn:hover {
  opacity: 0.7;
  color: #ffffff !important;
}
.del-lgn-btn {
  width: 100%;
}
@media (min-width: 768px) {
  .loginPara {
    margin-bottom: 218px;
  }
}
@media (max-width: 768px) {
  .btnGoogle {
    margin-bottom: 5px;
    margin-top: -4px;
  }
  .btnFB {
    margin-bottom: -2px;
  }
}
@media (min-width: 1280px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (min-width: 1440px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (min-width: 1880px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (min-width: 1920px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (min-width: 2880px) {
  .loginPara {
    margin-bottom: 204px;
  }
}
@media (max-width: 425px) {
  .firstCardCustomer {
    margin-bottom: 15px;
  }
  .btnGoogle {
    margin-bottom: 10px;
  }
}
.login-google {
  font-size: 29px;
  margin-left: 0px;
  margin-right: 15px;
}
.btn-facebook {
  background-color: #29487d !important;
  border-color: #29487d;
  display: inline-flex;
}
.btn-facebook:active,
._4jy1._42fs {
  background-color: #29487d;
  border-color: #29487d;
}

.btn-google {
  background-color: #d9534f !important;
  border-color: #29487d;
  display: inline-flex;
}
.btn-google:hover {
  background-color: #cc514e !important;
  border-color: #ffff !important;
}
.btn-facebook:hover {
  background-color: #365899 !important;
  border-color: #365899 !important;
}
.align-end {
  display: flex;
  align-items: center;
  justify-content: center;
}
.supiripola_delivery_logo {
  width: 246px;
  height: 80px;
  object-fit: contain;
}
.content-right {
  text-align: center;
  margin-bottom: 20px;
}
.row-submit-btn {
  margin-top: 40px;
}
.rect-right {
  background-color: rgba(87,121,174,0.7);
  padding: 30px;
  border-radius: 5px;
  margin-top: 20px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}
.signup-link {
  color: #ffd300;
  text-decoration: underline !important;
}
/* .signup-link:hover{
    color:#c8a21b; 
    text-decoration: underline!important;   
} */
</style>
