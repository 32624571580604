<template>
  <div style="">
    <ul class="nav nav-tabs">
      <li v-on:click="activetab = 1" :class="activetab === 1 ? 'active' : ''">
        <a data-toggle="tab" href="#personal">Personal</a>
      </li>
      <li v-on:click="activetab = 2" :class="activetab === 2 ? 'active' : ''">
        <a data-toggle="tab" href="#address">Addresses</a>
      </li>
      <!-- <li v-on:click="activetab=3" :class=" activetab === 3 ? 'active' : '' "><a data-toggle="tab" href="#bank">Bank Account Details</a></li> -->
      <!-- <li v-on:click="activetab=4" :class=" activetab === 4 ? 'active' : '' "><a @click="getRateDetails" data-toggle="tab" href="#settings">Delivery Settings</a></li> -->
      <li v-on:click="activetab = 5" :class="activetab === 5 ? 'active' : ''">
        <a data-toggle="tab" href="#password">Change Password</a>
      </li>
    </ul>
    <div class="tab-content content-inner content-inner-profile">
      <div v-if="activetab === 1" id="personal" class="tab">
        <ProfilePersonal />
      </div>
      <div v-if="activetab === 2" id="address" class="tab">
        <ProfileAddress />
      </div>
      <!-- <div v-if="activetab === 3" id="bank" class="tab">
                <ProfileBankAccount />
            </div>             -->
      <!-- <div v-if="activetab === 4" id="settings" class="tab">
                <ProfileSettings :postObject="postObject" :blockLoading="blockLoading"/>
            </div> -->
      <div v-if="activetab === 5" id="password" class="tab">
        <ProfilePassword />
      </div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import ProfilePersonal from "@/components/Deliver/profile/ProfilePersonal";
import ProfileAddress from "@/components/Deliver/profile/ProfileAddress";
import ProfileBankAccount from "@/components/Deliver/profile/ProfileBankAccount";
import ProfilePassword from "@/components/Deliver/profile/ProfilePassword";
import ProfileSettings from "@/components/Deliver/profile/ProfileSettings";
import ProfileKyc from "@/components/Deliver/profile/ProfileKyc";
import APIProfile from "../../../mixins/deliver/APIProfile";
export default {
  name: "Myaccount",
  mixins: [APIProfile],
  data() {
    return {
      breadcrumb: [
        {
          text: "Delivery Profile",
          active: true,
        },
      ],
      selectedContactType: "",
      postObject: {},
      blockLoading: 0,
      activetab: 1,
      pickup: true,
    };
  },
  components: {
    ProfilePersonal,
    ProfileAddress,
    ProfilePassword,
    ProfileSettings,
    ProfileBankAccount,
    ProfileKyc,
  },
  mounted: function() {},
  created: function() {
    this.handleBreadcrumb();
    // `this` points to the vm instance
  },
  methods: {
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    activateTab(tabnum) {
      tabnum = tabnum + 1;

      if (tabnum === 2) {
        this.$swal({
          title: "Please Update Address Information",
          text: "",
          type: "warning",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "OK",
        }).then((result) => {
          this.activetab = tabnum;
        });
      }

      if(tabnum === 3){ 
        this.$swal({
            title: 'Please Update Delivery Rates',
            text: "",
            type: 'warning',
            showCancelButton: false,
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'OK'
        }).then((result) => {
            this.$router.push("/deliver-rate-configure/0");    
        });
      }

      if (tabnum === 4) {
        if (this.pickup) {
          this.$swal({
            title: "Please Update Pickup Address",
            text: "",
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            this.$router.push("/deliver-pickup-address-submit/0");
          });
        } else {
          this.$swal({
            title: "Please Update Delivery Rates",
            text: "",
            type: "warning",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: "OK",
          }).then((result) => {
            this.$router.push("/deliver-rate-configure/0");
          });
        }
      }
    },
    checkRequiredStage: async function() {
      let response = await this.getDashboardStage();

      if (response !== null) {
        this.currentstage = response.complated_status;
        this.activateTab(this.currentstage);
        return response.complated_status;
      } else {
        this.currentstage = 1;
        this.activateTab(this.currentstage);
        return null;
      }
    },
    postRequiredStage: async function(stage) {
      let obj = {
        complated_status: stage,
      };
      await this.postDashboardStage(obj);
    },
    getRateDetails: async function() {
      try {
        this.blockLoading = 0;
        this.postObject = await this.getProfileBacicInfo();

        if (this.postObject.hasOwnProperty("pickup")) {
          this.pickup = this.postObject.pickup;
        } else {
          this.pickup = false;
        }

        this.blockLoading = 1;
      } catch (error) {
        this.$swal.fire({
          position: "center",
          type: "error",
          title: "Could not submit",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    //   createContact(){
    //       $('#mdl-create-contact').modal('show');
    //   }
  },
};
</script>
<style scoped>
.content-inner-profile {
  margin-bottom: 0px;
  padding-bottom: 0px;
  min-height: 607px !important;
}
</style>
