<template>
	<div class="row">
			<div id="content" class="col-sm-12 deliverrateconfigure">
				<div class="page-login">
				
					<div >
						<div class="row">
              
								<div class="col-sm-12 customer-login">
									<h2 style="padding-left: 20px;">Rate Configure</h2>
									<div class="well content-inner">										
                		<div class="row">
      								<div class="col-sm-3">
                        <label class="control-label " for="input-password">Zone One</label>
                        <!-- <select class="form-control" v-model="RateObj.zone_one">
                              <option value=""> --- Please Select --- </option>
                              <option  v-for="(item, index) in provincelist" :key="index" :value="item._id">{{ item.province_name}}</option>
                        </select> -->
                        <!-- <select class="form-control" v-model="RateObj.zone_one">
                              <option value=""> --- Please Select --- </option>
                              <option  v-for="(item, index) in zonelist" :key="index" :value="item._id">{{ item.name}}</option>
                        </!--> 
                        <input type="text" class="form-control" v-model="RateObj.zone_one" />
                      </div>
                      <div class="col-sm-3">
                        <label class="control-label " for="input-password">Zone Two</label>
                        <!-- <select class="form-control" v-model="RateObj.zone_one">
                              <option value=""> --- Please Select --- </option>
                              <option  v-for="(item, index) in provincelist" :key="index" :value="item._id">{{ item.province_name}}</option>
                        </select> -->
                        <!-- <select class="form-control" v-model="RateObj.zone_one">
                              <option value=""> --- Please Select --- </option>
                              <option  v-for="(item, index) in zonelist" :key="index" :value="item._id">{{ item.name}}</option>
                        </!--> 
                        <input type="text" class="form-control" v-model="RateObj.zone_two" />
                      </div>
                      <div class="col-sm-6">
                          <label class="control-label " for="input-password">Standard Rate Per Kilogram in LKR</label>
                          <input type="number" class="form-control" v-model="RateObj.standard_rate" />
                      </div>
                    </div> 
                		<!-- <div class="row">
      								<div class="col-sm-6">
                        <label class="control-label " for="input-password">Zone</label>
                        <select class="form-control" v-model="RateObj.zone_one">
                              <option value=""> --- Please Select --- </option>
                              <option  v-for="(item, index) in provincelist" :key="index" :value="item._id">{{ item.province_name}}</option>
                        </select>
                      </div>
                      <div class="col-sm-6">
                        <label class="control-label " for="input-password">Zone Two</label>
                        <select class="form-control" v-model="RateObj.zone_two">
                              <option value=""> --- Please Select --- </option>
                              <option  v-for="(item, index) in provincelist" :key="index" :value="item._id">{{ item.province_name}}</option>
                        </select>
                      </div>
                    </div>  -->
                    <!-- <br>  -->
                    <!-- <div class="row">
                      <div class="col-sm-6">
                        <label class="control-label " for="input-password">Special Rate Per Kilogram in LKR</label>
									  		<input type="number" class="form-control" v-model="RateObj.special_rate" />
                      </div>
                      <div class="col-md-6">
                        <label class="control-label " for="input-password">Handled Package Sizes</label> <br>
                        <label class="">
                          <input type="checkbox" v-model="RateObj.deliver_product_size" value="1" name="newsletter"> Small
                        </label>
                        <label class="radio-inline">
                          <input type="checkbox" v-model="RateObj.deliver_product_size" value="2" name="newsletter"> Medium
                        </label>
                        <label class="radio-inline">
                          <input type="checkbox" v-model="RateObj.deliver_product_size" value="3" name="newsletter"> Large
                        </label>
                      </div>
                    </div>  -->
                    <br> 
                    <div class="row">
                       <div class="col-sm-6">
                          <label class="control-label " for="input-password">Additional Weight Amount (Kilogram Per price in LKR)</label>
                          <input type="number" class="form-control" v-model="RateObj.additional_kg_price" />
                      </div>
                      <div class="col-sm-6">
                        <label class="control-label " for="input-password">Status</label>
                        <select class="form-control" v-model="RateObj.active_status">
                              <option value="1">Active</option>
                              <option value="2">Deactive</option>
                        </select>
                      </div>
                    </div>
                    <br> 
                    <div class="row">
                       <div class="col-sm-6">
                          <label class="control-label " for="input-password">Minimum Kilograms</label>
                          <input type="number" class="form-control" v-model="RateObj.min_kgs" />
                      </div>
                      <div class="col-sm-6">
                          <label class="control-label " for="input-password">Maximum Kilograms</label>
                          <input type="number" class="form-control" v-model="RateObj.max_kgs" />
                      </div>
                    </div>
                    <hr/>

                    <h2 id="recipesteps">Additional Rates</h2>
                    
                    <div
                      class="row"
                      v-for="(item, index) in RateObj.rates"
                      :key="index"
                      style="padding-bottom: 5px;"
                    >
                      <div class="col-md-2" v-if="item.charge!== undefined">
                        <div class="form-group">
                          <label class="control-label">charge</label>
                          <input
                            type="number"
                            v-model="item.charge"
                            placeholder="charge"
                            class="form-control"
                            min="0"
                          />
                        </div>
                      </div>
                      <div class="col-md-2" v-if="item.margin!== undefined">
                        <div class="form-group">
                          <label class="control-label">Margin</label>
                          <input
                            type="number"
                            v-model="item.margin"
                            placeholder="Margin"
                            class="form-control"
                            min="0"
                          />
                        </div>
                      </div>
                      <div class="col-md-2" v-if="item.max_kgs!== undefined">
                        <div class="form-group">
                          <label class="control-label">Max kgs</label>
                          <input
                            type="number"
                            v-model="item.max_kgs"
                            placeholder="Max kgs"
                            class="form-control"
                            min="0"
                          />
                        </div>
                      </div>
                      <div class="col-md-2" v-if="item.min_kgs!== undefined">
                        <div class="form-group">
                          <label class="control-label">Min kgs</label>
                          <input
                            type="number"
                            v-model="item.min_kgs"
                            placeholder="Min kgs"
                            class="form-control"
                            min="0"
                          />
                        </div>
                      </div>
                      <div class="col-md-2" v-if="item.reduce_weight!== undefined">
                        <div class="form-group">
                          <label class="control-label">Reduce weight</label>
                          <input
                            type="number"
                            v-model="item.reduce_weight"
                            placeholder="Reduce weight"
                            class="form-control"
                            min="0"
                          />
                        </div>
                      </div>
                      <div class="col-md-2" style="padding-top: 28px;">
                        <a @click="removeSteps(index)" v-if="(index) != 0">
                          <i class="fa fa-minus imgDel"></i>
                        </a>
                        <a @click="addSteps(index,item)" v-if="(index + 1) == RateObj.rates.length">
                          <i class="fa fa-plus imgAdd"></i>
                        </a>
                      </div>
                    </div>
                    
                    <hr/>
                    <h4 class="title">Delivery Rider Details</h4>

                    <div class="row">
                       <div class="col-sm-6">
                          <label class="control-label " for="input-password">Rider Name</label>
                          <input type="text" class="form-control" v-model="RateObj.name" />
                      </div>

                      <div id="mobileNumcol" class="col-sm-6">
                        <div class="form-group">
                          <label for="input-middlename" class="control-label"
                            >Mobile Number</label
                          >
                          <VuePhoneNumberInput
                            ref="mobilecom"
                            valid-color="green"
                            @input="validatePhone(RateObj.mobile)"
                            v-model="RateObj.mobile"
                            default-country-code="LK"
                            :translations="{ phoneNumberLabel: '' }"
                            :only-countries="['LK']"
                            no-example
                            style="height:30px; cursor:auto;"
                            id="mobileInput"
                          />
                        </div>
                      </div>
                    </div>
									</div>
									<div class="bottom-form">
										<input type="button" value="Back" class="btn merchprof-sbtn  pull-left" @click.prevent.stop="BackToList" />
										<input type="button" value="Submit" class="btn merchprof-sbtn pull-right"  @click.prevent.stop="RateSubmit"/>
									</div>
								</div>
						</div>
					</div>
				</div>
			</div>
		</div>
</template>
<script>
import mixinCommon from "@/mixins/APICommon";
import mixinRates from "@/mixins/deliver/APIRates"; 
import mixinProfile from "@/mixins/deliver/APIProfile";
import VuePhoneNumberInput from "vue-phone-number-input";
export default {
  mixins: [mixinCommon, mixinRates, mixinProfile],
  components: {
    VuePhoneNumberInput,
  },
  data: function () {
    return {
      hmobileValid: true,
      disableCon: true,
      clickValue: 1,
      isVisible: false,
      provincelist: [],
      zonelist: [
        {
          _id: "A",
          value: "A",
          name: "A"
        },
        {
          _id: "B",
          value: "B",
          name: "B"
        },
        {
          _id: "C",
          value: "C",
          name: "C"
        },
        {
          _id: "D",
          value: "D",
          name: "D"
        },
        {
          _id: "E",
          value: "E",
          name: "E"
        }
      ],
      RateObj: {
        zone_one: "",
        zone_two: "",
        active_status: 1,
        weight:1,
        deliver_product_size: ['1'],
        special_rate: 0,
        min_kgs: 0,
        rates: [
          {
            charge: 0,
            margin: 0,
            max_kgs: 0,
            min_kgs: 0,
            reduce_weight: 0
          }
        ],
        name: "",
        mobile: ''
      },
      packagesizes: [],
      breadcrumb: [
        {
          text: "Delivery Rates",
          href: "/deliver-rates",
          active: false
        },
        {
          text: "Delivery Rate Configure",
          href: "#",
          active: true
        }
      ],
      pickup: true
    }
  },
  computed: {
    rid() {
      let url = window.location.href.split("deliver-rate-configure/");
      return url[1];
    }
  },
  created(){
    this.handleBreadcrumb();
    this.getRateByIdCheck();
    this.handleProvince();
    this.checkRequiredStage();
  },
  mounted() {
    //  vertically align center the test , flag , +94

    this.$refs.mobilecom.$el.children[0].children[0].children[0].children[0].style.marginTop =
      "0px";
    this.$refs.mobilecom.$el.children[0].children[0].children[0].style.marginTop =
      "-4px";
    this.$refs.mobilecom.$el.children[0].children[0].children[1].style.paddingTop =
      "3px";
    this.$refs.mobilecom.$el.children[1].children[0].children[0].style.paddingTop =
      "3px";

    const mobileInputTp = document.querySelector(".input-tel__input");
    mobileInputTp.setAttribute("maxlength", 9);
  },
  methods: {
    removeSteps(index) {
      this.RateObj.rates.splice(index, 1);
    },
    addSteps(index, item) {
      let obj = item;
      this.RateObj.rates.push({
        charge: 0,
        margin: 0,
        max_kgs: 0,
        min_kgs: 0,
        reduce_weight: 0
      });
    },
    validatePhone(key) {
      if (key.length >= 10) {
        this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
          "1px solid green";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
          "green 0px 0px 0px 0px";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
          "green 0px 0px 0px 0px";
      }
      if (key.length < 10) {
        this.$refs.mobilecom.$children[1].$el.firstChild.style.border =
          "1px solid red";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.boxShadow =
          "red 0px 0px 0px 0px";
        this.$refs.mobilecom.$children[1].$el.firstChild.style.caretColor =
          "red 0px 0px 0px 0px";
      }

      if (!this.RateObj.hasOwnProperty("mobile")) {
        this.hmobileValid = false;
        this.hmobileError = "Enter valid phone number";
        return;
      }

      var str = key;
      var res = str.substr(0, 9);
      this.RateObj.mobile = res;
      key = res;

      if (key === "0") {
        this.RateObj.mobile = "";
      }
      key = key.replace(/^0+/, "");
      key = key.replace(/ /g, "");
      this.RateObj.mobile = key;

      if (key.length == 9) {
        if (key == this.RateObj.mobile.substring(3)) {
          this.hmobileValid = false;
          this.hmobileError = "Existing Phone Number Detected";
          this.$refs.mobilecom.$children[1].$el.firstChild.style.borderColor =
            "red";
        } else {
          this.hmobileValid = true;
        }
      } else if (key.length === 10 || key.length < 10) {
        this.hmobileValid = false;
        this.hmobileError = "Enter valid phone number";
      }
      if (key.length < 9) {
        this.disableCon = true;
      } else {
        this.disableCon = false;
      }
    },
    getRates: async function() {
        let response = await this.getProfileBacicInfo();
        if(response.pickup){
            this.pickup = true
        } else {
            this.pickup = false
        }
        // this.$parent.checkRequiredStage();
    },
    handleBreadcrumb: function() {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb
      });
    },    
    handleProvince: async function() {
      try {
          let response = await this.getProvince("5d8b9e5dfb195816aaea61b3"); 
          this.provincelist = response; 
              
      } catch (error) {
        this.$swal.fire({
                  position: 'center',
                  type: 'error',
                  title: error.data.message,
                  showConfirmButton: false,
                  timer: 1500
                })      
      }
    },
    checkRequiredStage: async function() {
        let response = await this.getDashboardStage();

        if(response !== null){
          
          if(response.complated_status < 2){
            this.$router.push('/deliver-profile');
          } 
        } else {
          this.$router.push('/deliver-profile'); 
        } 
    },
    postRequiredStage: async function(stage) {
        let obj = {
                "complated_status": stage
        }

        let response = await this.postDashboardStage(obj);
    },
    RateSubmit: async function() {
      // if(!this.RateObj.zone_one  || !this.RateObj.standard_rate){
      if(!this.RateObj.zone_one || !this.RateObj.zone_two || !this.RateObj.standard_rate){
         this.$swal.fire({
          position: 'center',
          type: 'error',
          title: 'Please Fill All Required Fields',
          showConfirmButton: false,
          timer: 1500
        });  
        return;
      }

      // if(this.RateObj.zone_one === "" ||  this.RateObj.standard_rate === ""){
      if(this.RateObj.zone_one === "" || this.RateObj.zone_two === "" || this.RateObj.standard_rate === ""){
         this.$swal.fire({
          position: 'center',
          type: 'error',
          title: 'Please Fill All Required Fields',
          showConfirmButton: false,
          timer: 1500
        });  
        return;
      }

      if(this.RateObj.deliver_product_size.length === 0){
         this.$swal.fire({
          position: 'center',
          type: 'error',
          title: 'Please Select A Package Size',
          showConfirmButton: false,
          timer: 1500
        });  
        return;
      }

      try {
        if (this.RateObj._id) {
          await this.putDeliveryRates(this.RateObj);
        } else {
          await this.postRequiredStage(4);
          this.$store.commit("updatedeliverystage", 4); 
          await this.postDeliveryRates(this.RateObj);
           
        }
        this.$router.push("/deliver-rates");        
      } catch (error) {
        this.$swal.fire({
          position: 'center',
          type: 'error',
          title: error.message,
          showConfirmButton: false,
          timer: 1500
        })      
      }
    },
    BackToList(){
      this.$router.push("/deliver-rates");        
    },
    getRateByIdCheck: async function() {
      if (this.rid !== "0") {
        try {
          let response = await this.getDeliveryRateByID(this.rid);
          this.RateObj = response;
          if (this.RateObj.rates.length === 0) {
            this.RateObj.rates.push({
              charge: 0,
              margin: 0,
              max_kgs: 0,
              min_kgs: 0,
              reduce_weight: 0
            });
          }
        } catch (error) {
          throw error; 
        }
      }
    }
  }
}
</script>
<style>
.deliverrateconfigure .merchprof-sbtn{
  background:#5779ae !important;
  color: #ffffff !important;
  border: none;
}
.deliverrateconfigure .merchprof-sbtn:hover{
  color: #000000 !important;
}

.imgAdd {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #6eaf5f;
  color: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 32px;
  margin-top: 0px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 15px;
}

.imgDel {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #d53f3f;
  color: #fff;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.2);
  text-align: center;
  line-height: 32px;
  margin-top: 0px;
  margin-right: 10px;
  cursor: pointer;
  font-size: 15px;
}
</style>
