import Domain from "../domain";
export default {
  methods: {
    checkUser: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "deliver/auth/check/user",
          obj,
          Domain.APP_BUYER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response;
      }
    },
    forgetPasswordOtp: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "deliver/auth/forgot/password/otp",
          obj,
          Domain.APP_BUYER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response;
      }
    },
    forgetPasswordOtpVerify: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "deliver/auth/forgot/password/otp/verify",
          obj,
          Domain.APP_BUYER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response;
      }
    },
    resetPassword: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "deliver/auth/forgot/password/sothosa",
          obj,
          Domain.APP_BUYER_HEADER
        );
        return response.data;
      } catch (error) {
        throw error.response;
      }
    }
  }
};
