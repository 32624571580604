import Domain from "../domain";
export default {
  methods: {
    getPreferences: async function() {
      try {
        let response = await this.axios.get(
          Domain.APP_DOMAIN + "merchant/order/setting",
          Domain.APP_MERCHANT_HEADER
        );
        return response.data;
      } catch (error) {
        throw error;
      }
    },
    postPreferences: async function(obj) {
      try {
        let response = await this.axios.post(
          Domain.APP_DOMAIN + "merchant/order/setting",
          obj,
          Domain.APP_MERCHANT_HEADER
        );
        if (!response || !response.data) {
          throw Error("resource not found.");
        }
        return response.data;
      } catch (error) {
        throw error;
      }
    },
  }
};
