<template>
    <div class="deliverratings">
        <p class="lead">Delivery Rating settings.</p>
        <div class="row">       
            <div class="col-sm-12">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Minimum Charge</label>
                            <input type="number" required class="form-control" v-model="object.min_range" placeholder="Min Charge">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Maximum Charge</label>
                            <input type="number" required class="form-control" v-model="object.max_range" placeholder="Max Charge">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Rate</label>
                            <input type="number" required class="form-control" v-model="object.amount" placeholder="Rate">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Select Rating Type</label>
                            <select v-model="object.charge_type" class="form-control">
                                <option v-for="(item, index) in filterStatusArray" :key="index" :value="item.value">{{item.text}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group text-right">
                            <button type="button" class="btn delprof-sbtn" @click="handleAddDateToTable" :disabled="!formIsValid">Add To Table</button>
                        </div>
                    </div>
                </div>
                <div class="col-md-12" id="preview-area" v-if="blockLoading === 0">
                    <div class="row text-center">
                        <div class="sk-chase">
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                            <div class="sk-chase-dot"></div>
                        </div>
                    </div>
                </div>
                <div class="row" v-if="blockLoading === 1">
                    <div class="col-sm-12">
                        <table id="example" class="table table-striped table-bordered" >
                            <thead>
                                <tr>
                                    <th scope="col">Minimum Charge ( Rs. )</th>
                                    <th scope="col">Maximum Charge ( Rs. )</th>
                                    <th scope="col">Rate ( Rs. )</th>
                                    <th scope="col">Rate Type</th>
                                    <th scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(column, index) in tableItems" :key="index">
                                    <td class="text-center">{{ column.min_range }}</td>
                                    <td class="text-center">{{ column.max_range }}</td>
                                    <td class="text-center">{{ column.amount }}</td>
                                    <td class="text-center">{{ column.charge_type === 1 ? "Number" : "Percentage ( % )" }}</td>
                                    <td class="product-action-style text-center">
                                        <button type="button" @click="handleEdit(column, index)" class="btn btn-sm btn-primary">Edit</button>&nbsp;
                                        <button type="button" @click="handleDelete(index)" class="btn btn-sm btn-danger">Delete</button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Pickup</label>
                            <select v-model="pickup" @change="handleChangePickUp($event.target.value)" class="form-control">
                                <option v-for="(item, index) in pickupArray" :key="index" :value="item.value">{{item.text}}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group">
                            <label for="input-middlename" class="control-label">Volumetric weight divided amount</label>
                            <input type="number" required class="form-control" v-model="deliverAmount" placeholder="Delivery Amount">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-12">
                        <div class="form-group text-right">
                            <button
                                :disabled="deliverAmount==0"
                                type="button"
                                class="btn delprof-sbtn"
                                @click="postRastes"
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import APIProfile from "../../../mixins/deliver/APIProfile";
import mixinRates from "@/mixins/deliver/APIRates"; 
export default {
    mixins: [APIProfile, mixinRates],
    props: {
        postObject: {
            type: Object,
            default: null
        },
        blockLoading: {
            type: Number,
            default: false
        }
    },
    data() {
        return {
            object: {
                min_range: "",
                max_range: "",
                amount: "",
                charge_type: ""
            },
            deliverAmount: 0,
            buyer: "",
            pickup: true,
            tableItems: [],
            postObj: {
                additional_charge_for_price: [],
                _id: "",
                pickup: true,
                vw_devied_amount: 0
            },
            filterStatusArray: [
                {
                    key: 1,
                    text: "Number",
                    value: 1
                },
                {
                    key: 2,
                    text: "Percentage ( % )",
                    value: 2
                }
            ],
            pickupArray: [
                {
                    key: 1,
                    text: "pick up to your store",
                    value: true
                }
                // {
                //     key: 2,
                //     text: "hand over",
                //     value: false
                // }
            ],
            brandnew: false
        }
    },
    watch: {
        postObject: {
            handler: function(obj) {
                if (obj instanceof Object) {
                    this.tableItems = obj.additional_charge_for_price;
                    this.buyer = obj._id;
                    this.pickup = obj.pickup;
                    this.deliverAmount = obj.vw_devied_amount != undefined ? obj.vw_devied_amount : 0;
                }
            },
            immediate: true,
            deep: true
        }
    },
    computed: {
        formIsValid() {
            return (
                this.object.min_range &&
                this.object.max_range &&
                this.object.amount &&
                this.object.charge_type
            );
        }
    },
    created(){
      this.getRates();  
    },
    methods: {
        handleChangePickUp(val) {
            if (val === "true") {
                this.postObj.pickup = true;
            } else {
                this.postObj.pickup = false;
            }
        },
        getRates: async function() {
            let response = await this.getProfileBacicInfo();
            if(response.hasOwnProperty('vw_devied_amount')){
                this.brandnew = false
            } else {
                this.brandnew = true
            }
            
            console.log(response);
            // this.$parent.checkRequiredStage();
        },
        postRastes: async function() {
            try {
                for (let x = 0; x < this.tableItems.length; x++) {
                    delete this.tableItems[x]._id;
                }

                if(this.brandnew){ 
                    await this.$parent.postRequiredStage(3);
                    this.$store.commit("updatedeliverystage", 3); 
                }

                this.postObj.additional_charge_for_price = this.tableItems;
                this.postObj._id = this.buyer;
                this.postObj.vw_devied_amount = this.deliverAmount;
                
                let res = await this.putProfileBacicInfo(this.postObj);
                this.brandnew = false;
                this.$store.commit("updatePickUpdelivery", this.postObj.pickup);
                
                this.$swal.fire({
                    position: 'center',
                    type: 'success',
                    title: "Successfully submit",
                    showConfirmButton: false,
                    timer: 1500
                })

                if(this.brandnew){ 
                   if(this.pickup){
                        this.$router.push("/deliver-pickup-address-submit/0");                       
                    } else {
                        this.$router.push("/deliver-rate-configure/0");     
                    } 
                    return;
                } 
                
                this.$parent.checkRequiredStage();

            } catch (error) {
                this.$swal.fire({
                    position: 'center',
                    type: 'error',
                    title: "Could not submit",
                    showConfirmButton: false,
                    timer: 1500
                })
            }
        },
        handleDelete(index) {
            let self = this;
            this.$swal.fire({
                title: 'Are you sure?',
                text: "You won't be able to revert this!",
                type: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes, delete it!'
            }).then((result) => {
                if (result.value) {
                    self.delete(index);
                }
            })
        },
        delete(id) {
            this.tableItems.splice(id, 1);
            this.$swal.fire({
                position: 'center',
                type: 'success',
                title: "Successfully deleted",
                showConfirmButton: false,
                timer: 1500
            })
        },
        handleEdit(obj, id) {
            this.object = obj;
            this.tableItems.splice(id, 1);
        },
        handleAddDateToTable() {
            this.object.min_range = parseInt(this.object.min_range);
            this.object.max_range = parseInt(this.object.max_range);
            this.object.amount = parseInt(this.object.amount);
            this.tableItems.push(this.object);
            this.object = {};
        }
    }
}
</script>
<style>
.deliverratings .delprof-sbtn{
    background: #5779ae !important;
    color: #ffffff !important;
    border: none;
}
.deliverratings .delprof-sbtn:hover{
    color: #000000 !important;
}

</style>
